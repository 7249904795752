import React, { useEffect, useState, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { capitalize } from 'lodash';
import Cookies from 'js-cookie';
import * as routerSelectors from 'app/selectors/router';
import FailureState from 'app/components/customer/dashboard/FailureState';
import { DangerDialog } from 'app/components/common/Dialog';
import { AvailableProducts } from 'app/constants/Products';
import BeenHereBefore from './Parts/BeenHereBefore';
import LoverRegistration from './LoverRegistration';
import KingRegistration from './KingRegistration';
import WarriorRegistration from './WarriorRegistration';
import MagicianRegistration from './MagicianRegistration';
import LabsRegistration from './LabsRegistration';
import DrawerMenu from 'app/components/common/DrawerMenu';
import { Drawer, NavBar } from 'mui';
import { getAmplitudeExperiment } from 'app/utils/amplitudeExperiments';
import Spinner from 'app/components/customer/Spinner';

const mapStateToProps = (state) => {
  const feedbackParam = routerSelectors.selectRouterQuery(state).get('registration_feedback');
  const currentProduct = (() => {
    const product = routerSelectors.selectRouterQuery(state).get('product');

    if (product === 'labs') return product;

    return AvailableProducts[capitalize(product)] || AvailableProducts.King;
  })();
  const testMode = routerSelectors.selectRouterQuery(state).get('test_mode');

  return {
    feedbackParam,
    currentProduct,
    initialValues: {
      settings: {
        product: currentProduct,
        test_mode: testMode,
      },
      step0: {},
      step2: {
        state: '',
        pc_opt_in: false,
        mso_opt_in: false,
        over_18: false,
      },
      step3: {
        first_name: '',
        last_name: '',
      },
      step4: {
        email: window.emailFromTypeform,
        password: '',
        password_confirmation: '',
      },
    },
  };
};

const Registration = ({ feedbackParam, currentProduct }) => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [experimentVariant, setExperimentVariant] = useState<string | null>(null);

  useEffect(() => {
    if (Cookies.get('email') && currentProduct !== 'labs') {
      setShowLoginModal(true);
    }
  }, []);

  useLayoutEffect(() => {
    const timeoutId = setTimeout(() => {
      // Fallback after 2 seconds if the experiment doesn't respond
      if (experimentVariant === null) {
        setExperimentVariant('off');
      }
    }, 2000);

    try {
      getAmplitudeExperiment('registration_state_selection', (variant) => {
        setExperimentVariant(variant.key || 'registration_state_selection_variation_0');
        clearTimeout(timeoutId);
      }).catch(() => {
        setExperimentVariant('off');
        clearTimeout(timeoutId);
      });
    } catch (error) {
      setExperimentVariant('off');
      clearTimeout(timeoutId);
    }

    return () => clearTimeout(timeoutId);
  }, []);

  if (feedbackParam) {
    return (
      <Drawer sideContent={<DrawerMenu loggedIn={false} />}>
        <NavBar />
        <FailureState state={feedbackParam} currentProduct={currentProduct} />
      </Drawer>
    );
  }

  if (!experimentVariant)
    return (
      <div className="global-spinner">
        <Spinner isCenter={true} />
      </div>
    );

  const Step0 = {
    king: KingRegistration,
    lover: LoverRegistration,
    warrior: WarriorRegistration,
    magician: MagicianRegistration,
    labs: LabsRegistration,
  }[currentProduct];

  const content = (
    <>
      <DangerDialog
        isOpen={showLoginModal}
        className="cookie-email-modal"
        onClose={() => setShowLoginModal(false)}
        title="Been Here Before?"
        text={<BeenHereBefore setShowLoginModal={setShowLoginModal} />}
      />
      <Step0 experimentVariant={experimentVariant} />
    </>
  );

  return (
    <Drawer sideContent={<DrawerMenu loggedIn={false} />}>
      <NavBar />
      {content}
    </Drawer>
  );
};

export default connect(mapStateToProps)(Registration);
