import React, { useLayoutEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/helpers/hooks';
import {
  selectCurrentIntake,
  selectCustomer,
  selectCustomerProduct,
  selectFurthestProductStepPath,
} from 'app/selectors/customer';
import { push } from 'connected-react-router/immutable';
import { apiRequestUserCommand } from 'app/actions/customer';
import { Formik } from 'formik';
import BackNextFooter from 'app/components/customer/steps/BackNextFooter';
import createCheckoutHelper from 'app/helpers/createCheckoutHelper';
import DiscountBanner from '../DiscountBanner';
import { PageWrapper } from 'mui';
import { trackEvent } from 'app/utils/useSegmentTracking';
import { AvailableProducts } from 'app/constants/Products';

const MultimonthPlanSelectionStep = () => {
  const intake = useAppSelector(selectCurrentIntake);
  const customer = useAppSelector(selectCustomer);
  const productName = intake.get('product_name');
  const product = useAppSelector((state) => selectCustomerProduct(state, productName));
  const dispatch = useAppDispatch();

  const checkoutHelper = createCheckoutHelper(intake, customer, product, true);

  const goToSelectProductPage = push(checkoutHelper.selectProductStepPath());

  const onSuccessActionCreator = (_, stateAfterSuccess) =>
    push(selectFurthestProductStepPath({ customer: stateAfterSuccess }, productName));

  const initialValues = checkoutHelper.productSpecificInitialValues();
  const handleChangeProduct = () => dispatch(goToSelectProductPage);

  const onSubmit = (params: any) => {
    const action = apiRequestUserCommand({
      cmdType: 'select_multimonth_plan',
      params: { ...params, intake: intake.get('name') },
      context: { onSuccessActionCreator },
    });
    dispatch(action);
  };

  useLayoutEffect(() => {
    if (customer && productName === AvailableProducts.King) {
      const facebookData = customer?.get('facebook_friendly_user_data_h')?.toJS() || {};
      trackEvent('ViewedPlanSelectionPage', {
        ...facebookData,
        protocol: productName,
      });
    }
  }, [productName, customer]);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <PageWrapper wrapperClassName="flex flex-col gap-y-6 lg:gap-y-8">
          <DiscountBanner />
          <h3 className="ml-10 sm:ml-5">Choose Your Plan</h3>
          {checkoutHelper.renderMultimonthStep(handleChangeProduct, true)}
          <BackNextFooter noBack={true} onNext={handleSubmit} testId="submit-plan-selection" />
        </PageWrapper>
      )}
    </Formik>
  );
};

export default MultimonthPlanSelectionStep;
