import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/helpers/hooks';
import * as selectors from 'app/selectors/customer';
import {
  selectCurrentIntake,
  selectCurrentIntakeProduct,
  selectCustomer,
  selectCustomerShippingAddress,
  selectLatestPaymentMethodErrorMessage,
} from 'app/selectors/customer';
import { FormikProps, useFormikContext } from 'formik';
import 'app/components/customer/steps/Payment/css/Payment.scss';
import 'app/components/customer/steps/Payment/css/GenericPaymentPage.scss';
import GenericPaymentMethod from 'app/components/customer/steps/Payment/Generic/GenericPaymentMethod';
import { push } from 'connected-react-router/immutable';
import GenericDueToday from 'app/components/customer/steps/Payment/Generic/GenericDueToday';
import SubscriptionWidget from 'app/components/customer/steps/Payment/Generic/SubscriptionWidget';
import {
  AvailableProducts,
  KingV2SubProducts,
  LabKitTypes,
  MultiMonthPlanMapping,
  WarriorSubProducts,
} from 'app/constants/Products';
import { NOOP } from 'app/actions/customer';
import GenericShippingForm from './GenericShippingForm';
import createCheckoutHelper from 'app/helpers/createCheckoutHelper';
import OwnLabFileUploadedSection from 'app/components/customer/steps/Payment/Generic/OwnLabFileUploadedSection';
import UnavailableProductModal from 'app/components/customer/steps/Payment/Generic/UnavailableProductModal';
import SetProductDialog from '@setproduct-ui/core/Dialog/Dialog';
import { useExperiment } from 'app/utils/useExperiment';
import { Card, PageWrapper } from 'mui';
import Dermastamp from 'app/components/customer/steps/Payment/Dermastamp';
import WarriorSafetyAcknowledgment from 'app/components/customer/steps/Payment/warrior/WarriorSafetyAcknowledgment';

type Props = {
  isOnboarding: boolean;
  errorMessage?: string;
  setOptInParams?: (e: any) => void;
};

const GenericPaymentForm = ({ errorMessage, setOptInParams, isOnboarding }: Props) => {
  const dispatch = useAppDispatch();
  const { values, handleSubmit, isSubmitting, isValid, setFieldValue } = useFormikContext<{
    include_supplement: boolean;
    use_own_lab: boolean;
    lab_kit_type: LabKitTypes;
    own_lab_file_uploaded: boolean;
    selfPay: boolean;
    daily_strength: string;
    drug_variant: string;
    number_of_doses: 4 | 12 | 8;
    multimonth_plan: 1 | 3 | 6 | 12;
    selected_king_v2_product: KingV2SubProducts;
    warrior_strength: WarriorSubProducts;
    include_dermastamp: boolean;
  }>();
  const { selfPay, multimonth_plan } = values;
  const customer = useAppSelector(selectCustomer);
  const currentProductName = useAppSelector(selectCurrentIntakeProduct);
  const product = useAppSelector((state) => selectors.selectCustomerProduct(state, currentProductName));
  const intake = useAppSelector(selectCurrentIntake);

  const [productPharmacyValid, setProductPharmacyValid] = useState(true);

  const subscriptionPaidSuccessfuly = intake.get('customer_subscription_successful');
  const labPaidSuccessfuly = intake.get('customer_lab_payment_successful');

  const labsFreeExperiment =
    useExperiment('testosterone_discount_labs_free', 'testosterone_discount_labs_free_variation_0') ===
    'testosterone_discount_labs_free_variation_1';

  const isLabsFree = isOnboarding && currentProductName === 'king' && labsFreeExperiment;

  const checkoutHelper = createCheckoutHelper(intake, customer, product, true);
  const priceCalculator = checkoutHelper.priceCalculator(
    values,
    subscriptionPaidSuccessfuly,
    isLabsFree || labPaidSuccessfuly,
  );

  const latestPaymentMethodErrorMessage = useAppSelector(selectLatestPaymentMethodErrorMessage);

  const subProductName = checkoutHelper.subProductNameForIntakeCheckout();
  const multimonthPeriod = MultiMonthPlanMapping[multimonth_plan];

  const paymentErrorMessage = errorMessage || customer.get('payment_error_message') || latestPaymentMethodErrorMessage;
  const currentPaymentMethod = customer.get('payment_method');

  const showLabKit = checkoutHelper.showLabKitOnboarding();
  const shippingAddress = useAppSelector(selectCustomerShippingAddress);
  const ownLabActions = checkoutHelper.showOwnLabSection();
  const isKingTrt = checkoutHelper.isKingTrt(subProductName);

  const [shippingFormValid, setShippingFormValid] = useState(false);
  const [oralTrtAvailable, setOralTrtAvailable] = useState(true);
  const [unavailableProductDialog, setUnavailableProductDialog] = useState(false);
  const [openModalAcknowledgment, setOpenModalAcknowledgment] = useState(false);

  const isMinoxidil = values.warrior_strength === 'oral_minoxidil' || values.warrior_strength === 'topical_minoxidil';
  const patientARAcknowledgment = intake.get('questionnaire')?.get('ar_inhibitors_confirmation');

  useEffect(() => {
    if (
      currentProductName == AvailableProducts.Warrior &&
      intake.get('type') === 'warrior_onboarding' &&
      !isMinoxidil &&
      !patientARAcknowledgment
    ) {
      setOpenModalAcknowledgment(true);
    }
  }, [patientARAcknowledgment, isMinoxidil, currentProductName]);

  const handleAcknowledgmentClose = () => {
    setOpenModalAcknowledgment(false);
    dispatch(push('sub_product_selection'));
  };

  const shippingFormRef = useRef<FormikProps<any>>(null);
  const onSubmit = async (applePayPaymentMethod: any = null) => {
    if (!shippingAddress && !shippingFormValid) return;

    if (isKingTrt) {
      const customerState = shippingFormRef.current?.values?.state || shippingAddress.get('state');
      const kingV2AvailableStates = intake.get('king_v2_available_states');
      const validOralTrtState = kingV2AvailableStates.includes(customerState);
      setUnavailableProductDialog(!validOralTrtState);
      setOralTrtAvailable(validOralTrtState);
      if (!validOralTrtState) return;
    }

    if (shippingFormRef.current?.dirty) {
      const response = (await shippingFormRef.current.submitForm()) as { type: string } | undefined;
      if (response?.type === NOOP) return;
    }

    if (applePayPaymentMethod?.paymentMethodId) {
      setFieldValue('applePayPaymentMethod', applePayPaymentMethod);
    }

    handleSubmit();
  };

  const renderDueToday = () => (
    <GenericDueToday
      multimonthPeriod={multimonthPeriod}
      multimonthPlan={multimonth_plan}
      starterPack={checkoutHelper.productSpecificInitialValues()['starter_pack']}
      isOnboarding={isOnboarding}
      priceCalculator={priceCalculator}
      showLabKit={showLabKit}
      intake={intake}
    />
  );

  const renderSubscriptionWidget = () => {
    return (
      <SubscriptionWidget
        currentProductName={currentProductName}
        priceCalculator={priceCalculator}
        multimonthPeriod={multimonthPeriod}
        multimonthPlan={multimonth_plan}
        isOnboarding={isOnboarding}
        supplementsOnAnotherIntake={checkoutHelper.supplementsOnAnotherIntake()}
        checkoutHelper={checkoutHelper}
        productChanged={false}
        subProductName={checkoutHelper.productSpecificInitialValues()['starter_pack'] ? 'starter_pack' : subProductName}
        activeProductWithSupplementName={checkoutHelper.activeProductWithSupplementName()}
      />
    );
  };

  const renderShippingAddressWidget = () => {
    return (
      !shippingAddress && (
        <GenericShippingForm
          shippingFormRef={shippingFormRef}
          productPharmacyValid={productPharmacyValid}
          setProductPharmacyValid={setProductPharmacyValid}
          setShippingFormValid={setShippingFormValid}
          currentProductName={currentProductName}
        />
      )
    );
  };

  const renderGenericCheckoutSection = () => {
    return (
      <>
        <h5 className="font-medium">Due Today</h5>
        {renderDueToday()}
        <hr className="mt-3" />
        <GenericPaymentMethod
          paymentErrorMessage={paymentErrorMessage}
          currentPaymentMethod={currentPaymentMethod}
          setOptInParams={setOptInParams}
          onCheckout={onSubmit}
          priceCalculator={priceCalculator}
          shippingFormRef={shippingFormRef}
          setUnavailableProductDialog={setUnavailableProductDialog}
          bodyClassName={''}
          disabled={
            isSubmitting ||
            !isValid ||
            !selfPay ||
            !productPharmacyValid ||
            (!shippingAddress && !shippingFormValid) ||
            (isKingTrt && !oralTrtAvailable)
          }
        />
      </>
    );
  };

  const renderOralTrtUnavailableModal = () => {
    return (
      <SetProductDialog
        isOpen={unavailableProductDialog}
        title="TRT is Not Currently Available in Your State"
        text={
          <UnavailableProductModal
            setUnavailableProductDialog={setUnavailableProductDialog}
            setOralTrtAvailable={setOralTrtAvailable}
          />
        }
        className="manage_subscription__cancel_modal align-left"
        showCloseModalIcon={false}
      />
    );
  };

  return (
    <PageWrapper wrapperClassName="flex flex-col gap-y-6 lg:gap-y-8">
      <div className="px-4">
        <h3 className="mb-3">Purchase</h3>
        {checkoutHelper.checkoutBreadcrumbs(dispatch)}
      </div>
      <Card>
        <Card.Body>
          {renderSubscriptionWidget()}
          {renderShippingAddressWidget()}
        </Card.Body>
      </Card>
      {ownLabActions && (
        <Card>
          <Card.Body>
            <OwnLabFileUploadedSection />
          </Card.Body>
        </Card>
      )}
      {checkoutHelper.displayDermaOption() && window.enableDermastamp === '1' && (
        <Dermastamp priceCalculator={priceCalculator} isOnboarding={isOnboarding} />
      )}
      <Card>
        <Card.Body>
          {renderGenericCheckoutSection()}
          {renderOralTrtUnavailableModal()}
        </Card.Body>
      </Card>
      <SetProductDialog
        isOpen={openModalAcknowledgment}
        title={<h4 className="subtitle bold mb0">Safety Information</h4>}
        text={
          <WarriorSafetyAcknowledgment
            handleSubmit={() => setOpenModalAcknowledgment(false)}
            selectOtherTreatments={handleAcknowledgmentClose}
          />
        }
        onClose={handleAcknowledgmentClose}
        className="manage_subscription__cancel_modal align-left"
      />
    </PageWrapper>
  );
};

export default GenericPaymentForm;
