import React, { FC, ReactNode } from 'react';
import Radio from 'app/components/common/Radio';
import PriceFormatter from '../Generic/PriceFormatter';
import '../css/LoverTreatmentPlan.scss';
import { useFormikContext, Field } from 'formik';
import cx from 'classnames';
import { ImagesMapping } from 'app/components/customer/steps/Payment/king/ProductImage';
import { AvailableProducts } from 'app/constants/Products';
import {
  LOVER_SUBSCRIPTION_MONTHLY_COST,
  LOVER_SUBSCRIPTION_MONTHLY_COST_VARIANT_2,
  LOVER_SUBSCRIPTION_MONTHLY_COST_EXP_V1,
  LOVER_SUBSCRIPTION_MONTHLY_COST_EXP_V2,
} from 'app/helpers/productSpecificPriceCalculators/loverPriceCalculator';
import { useExperiment } from 'app/utils/useExperiment';
import { Card, Badge, FormSelect, Button } from 'mui';
import protocolNames from 'app/utils/protocolNames';

const RadioLabel: FC<{
  title: string;
  subheader?: ReactNode;
  paragraph?: ReactNode;
  icon?: string;
  dosePrice?: string;
  totalPrice: number;
  multimonthEnabled?: boolean;
}> = ({ title, subheader, paragraph, icon, totalPrice, multimonthEnabled }) => (
  <div className="lg:-mt-2">
    <div>
      <div className="flex flex-row justify-between items-center">
        <h5>{title}</h5>
        {multimonthEnabled ? (
          <PriceFormatter price={totalPrice} from={true} period="mo" className="mtn15" />
        ) : (
          <PriceFormatter price={totalPrice} className="mtn15" />
        )}
      </div>
      <p>{subheader}</p>
    </div>
    <br />
    <p className="paragraph">{paragraph}</p>
    {icon && <img src={icon} alt="" />}
  </div>
);

const LoverTreatmentPlan = ({ product, multimonthEnabled, handleSubmit = undefined }) => {
  const { setFieldValue, values, dirty } = useFormikContext<any>();

  const bloodflow_variant_2 =
    useExperiment('bloodflow_multimonth', 'bloodflow_multimonth_variation_0') === 'bloodflow_multimonth_variation_2';
  const bloodflow_experiment_pricing_q1_25 = useExperiment(
    'bloodflow_pricing_q1_25',
    'bloodflow_pricing_q1_25_variation_0',
  );

  let priceConfig = bloodflow_variant_2 ? LOVER_SUBSCRIPTION_MONTHLY_COST_VARIANT_2 : LOVER_SUBSCRIPTION_MONTHLY_COST;

  switch (bloodflow_experiment_pricing_q1_25) {
    case 'bloodflow_pricing_q1_25_variation_1':
      priceConfig = LOVER_SUBSCRIPTION_MONTHLY_COST_EXP_V1;
      break;
    case 'bloodflow_pricing_q1_25_variation_2':
      priceConfig = LOVER_SUBSCRIPTION_MONTHLY_COST_EXP_V2;
      break;
    default:
      // Keep the initial pricing based on bloodflow_multimonth experiment
      break;
  }

  const defaultMultimonthPlan = multimonthEnabled ? 12 : 1;

  const options = [4, 8, 12].map((dose) => {
    const monthlyCost = priceConfig[dose.toString()][defaultMultimonthPlan] / 100;
    const doseCost = (monthlyCost / dose).toFixed(2);

    return {
      label: `${dose} doses, $${monthlyCost}/mo, $${doseCost}/dose`,
      value: dose,
    };
  });

  const handleLegacyOption = () => {
    setFieldValue('number_of_doses', null);
    setFieldValue('daily_strength', null);
  };
  const handleDailyStrengthChange = (value) => {
    setFieldValue('number_of_doses', null);
    setFieldValue('daily_strength', value);
  };
  const handleNumberOfDosesRadioClick = () => {
    setFieldValue('number_of_doses', 4);
    setFieldValue('daily_strength', null);
  };

  return (
    <Card>
      <Card.Body>
        <div>
          <figure className="h-[180px] lg:h-[240px] mb-4">
            <img className="h-full w-auto" src={ImagesMapping[AvailableProducts.Lover]} alt="Product" />
          </figure>

          <div className="text-center mt-4">
            <Badge variant={AvailableProducts.Lover} className="mb-2">
              {protocolNames[AvailableProducts.Lover]}
            </Badge>
          </div>
        </div>

        <div className="lover_treatment_plan__option">
          <Radio
            className="no-borders top-aligned"
            onChange={() => handleDailyStrengthChange('2_5_mg')}
            checked={'2_5_mg' === values.daily_strength}
            label={
              <RadioLabel
                paragraph="Best if you’ve never used PDE5 inhibitors before, are looking to optimize cognitive performance, and sexual benefits are your lowest priority."
                subheader="2.5mg Tadalafil + 2.5mg Vardenafil"
                title="Daily Use - 2.5mg"
                dosePrice="3.33"
                totalPrice={priceConfig['2_5_mg'][defaultMultimonthPlan]}
                multimonthEnabled={multimonthEnabled}
              />
            }
            testId="daily_strength_2_5_mg"
          />
        </div>
        <hr />
        <div className="lover_treatment_plan__option">
          <Radio
            className="no-borders top-aligned"
            onChange={() => handleDailyStrengthChange('5_mg')}
            checked={'5_mg' === values.daily_strength}
            label={
              <RadioLabel
                paragraph="Best if you’ve used PDE5 inhibitors before, you’re looking to optimize muscle pumps and recovery, sexual benefits are a priority, or you want to achieve harder erections."
                subheader="5.0mg Tadalafil / 5.0mg Vardenafil"
                title="Daily Use - 5.0mg"
                dosePrice="5"
                totalPrice={priceConfig['5_mg'][defaultMultimonthPlan]}
                multimonthEnabled={multimonthEnabled}
              />
            }
            testId="daily_strength_5_mg"
          />
        </div>
        <hr />
        <div className="lover_treatment_plan__option">
          <Radio
            checked={!!values.number_of_doses}
            onChange={handleNumberOfDosesRadioClick}
            className="no-borders top-aligned mb-4"
            label={
              <div>
                <RadioLabel
                  paragraph="Best if you want something when you need it rather than daily, sexual benefits are your main
                    priority, and you want a hard erection for a longer time."
                  subheader="10mg Tadalafil / 10mg Vardenafil"
                  title="As Needed (10mg)"
                  totalPrice={priceConfig['4'][defaultMultimonthPlan]}
                  multimonthEnabled={multimonthEnabled}
                />
                <p className="mt-4">Doses per 30 days</p>
                <Field name="number_of_doses">
                  {({ field, form, meta }) => (
                    <FormSelect
                      className={cx('select', {
                        '-red': meta.error,
                      })}
                      classNamePrefix="select"
                      value={options.find((_) => _.value === field.value || _.value === values.number_of_doses)}
                      options={options}
                      placeholder="Select"
                      isSearchable={false}
                      onChange={(v) =>
                        form.setFieldValue('number_of_doses', v?.value) && form.setFieldValue('daily_strength', null)
                      }
                    />
                  )}
                </Field>
              </div>
            }
            testId="as_needed"
          />
        </div>

        {!!product.get('opt_in_choice')?.get('is_legacy') && (
          <div className="lover_treatment_plan__option">
            <Radio
              className="no-borders top-aligned"
              onChange={handleLegacyOption}
              checked={!dirty || (!values.number_of_doses && !values.daily_strength)}
              label={
                <RadioLabel
                  subheader="5mg tadalafil + 2mg bremelanotide"
                  title="Lover Legacy (Daily Use)"
                  dosePrice="5"
                  totalPrice={149.99}
                  multimonthEnabled={multimonthEnabled}
                />
              }
            />
          </div>
        )}
        {handleSubmit && (
          <>
            <hr />

            <Button onClick={handleSubmit} data-testid="submit-product-selection">
              Continue
            </Button>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default LoverTreatmentPlan;
