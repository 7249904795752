import React, { FC, useContext, useEffect } from 'react';
import './css/HomePage.scss';
import HomePageSocialMedia from './HomePageSocialMedia';
import ProtocolCard from './ProtocolCard';
import ProgressTrackerUpNext from 'app/components/customer/ProgressTracker/ProgressTrackerUpNext';
import ProgressTrackerFAQ from 'app/components/customer/ProgressTracker/ProgressTrackerFAQ';
import { PROTOCOLS } from './helpers/protocols';
import * as Routes from 'app/constants/Routes';
import IcoBackFrame from 'images/ico-back-frame.svg';
import { push } from 'connected-react-router/immutable';
import { useAppDispatch, useAppSelector } from 'app/helpers/hooks';
import { selectCustomer, selectCustomerProduct } from 'app/selectors/customer';
import { ProductContext } from 'app/components/customer/Product';
import { AvailableProducts } from 'app/constants/Products';
import homePageShipmentNotifications from 'app/components/customer/dashboard/HomePage/helpers/homePageShipmentNotifications';

const HomePageBack: FC = () => {
  const dispatch = useAppDispatch();
  const handleClick = () => {
    dispatch(push(Routes.Dashboard));
  };

  return (
    <button onClick={handleClick} className="link gap-3 mt-2" style={{ display: 'flex', alignItems: 'center' }}>
      <img src={IcoBackFrame} alt="Back" /> Back to Dashboard
    </button>
  );
};

const ProtocolPage: FC<{ protocolName: string }> = ({ protocolName }) => {
  const customer = useAppSelector(selectCustomer);
  const protocol = PROTOCOLS.find((protocol) => protocol.slug === protocolName) || PROTOCOLS[0];
  const product = useAppSelector((state) => selectCustomerProduct(state, protocol.slug));
  const productCtx = useContext(ProductContext);
  productCtx.change(protocol.slug as AvailableProducts);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const notifications = homePageShipmentNotifications(customer)
    .filter(({ protocolName: product }) => product === protocolName)
    .map(({ title, description, trackingUrl }) => ({
      title,
      description,
      trackingUrl,
    }));

  return (
    <>
      <div className="dash-bg" />
      <div className="dash centered flex-column flex-between px-5 gap-8 mb-10">
        <HomePageBack />
        <ProtocolCard
          protocol={protocol}
          items={[]}
          notifications={notifications}
          {...(product.get('current_status') === 'active' && {
            link: {
              label: 'Change Product or Plan',
              url: Routes.UpdateSubscriptionForProduct(protocolName),
            },
          })}
        />
      </div>
      <div className="home-page-legacy-wrapper centered">
        <ProgressTrackerUpNext />
        <ProgressTrackerFAQ />
      </div>
      <HomePageSocialMedia iconsOnTop={false} />
    </>
  );
};

export default ProtocolPage;
