import React from 'react';
import { PrimaryButton } from 'app/components/common/Button';
import '../css/GenericRefundPolicy.scss';

const GenericRefundPolicy = ({ onClose }) => (
  <div className="generic-refund-policy">
    <span className="bold"> Eligibility </span>
    <p className="mb-3">
      Maximus Inc. does not guarantee that medication will be prescribed by the attending provider as they may determine
      medication is not medically necessary and/or, does not meet prescription policy or is not telemedicine
      appropriate.
    </p>
    <p className="mb-3">
      Your attending provider will use self-reported symptoms and, for select protocols, lab-validated biomarkers to
      assess your eligibility. Most individuals are eligible for treatment.
    </p>
    <p className="mb-3">
      If you are determined ineligible by your assigned Maximus provider, your subscription will be refunded in full,
      excluding the cost of the provider visit ($30).
    </p>
    <span className="bold"> Canceling/changing Subscription </span>
    <p className="mb-3">
      We do not issue refunds for prescription products that are being prepared for shipment or have shipped.
    </p>
    <p className="mb-3">
      Our team needs a minimum of 48 hours notice prior to your next order processing date in order to cancel or make
      adjustments to your subscription.
    </p>
    <p className="mb-3 ml-4">
      <ul>
        <li>For an initial order, the order processing date is the date the Rx is received by the pharmacy</li>
        <li>For refill requests, the order processing date is the date that auto-refill starts</li>
      </ul>
    </p>
    <p className="mb-3">
      In the event that we are not able to stop a shipment in time prior to the treatment being dispatched, you may be
      entitled to a refund if you have given 48-hour notice but still received a charge.
    </p>
    <p className="mb-3">
      For purchasers of our multi-month plans, no refund will be given once your subscription begins and you have
      received your first month of supply. If you elect to sign up for a one month plan, you can change to a multi-month
      plan at any time, which will go into effect on your next billing cycle.
    </p>
    <p className="mb-3">
      We understand that you may have concerns about the medication not working, and so we recommend the monthly plan to
      complete your blood tests, see your response to the medication, and consult with your doctor about the pros and
      cons of treatment. You can then avail the yearly plan at any time with a quick selection in your profile.
    </p>
    <p className="mb-3">
      You may be eligible for a partial refund if your doctor determines the protocol is not appropriate for you.
    </p>
    <p className="mb-3">
      If you feel we’ve made an error with your purchase, please contact us at{' '}
      <a className="blue" target="_blank" href="mailto:support@maximustribe.com" rel="noreferrer">
        support@maximustribe.com
      </a>
      .
    </p>
    <PrimaryButton text="Ok" onClick={onClose} />
  </div>
);

export default GenericRefundPolicy;
