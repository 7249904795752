import React from 'react';
import { Provider } from 'react-redux';

import { Fetch } from 'app/Fetch';
import Cheatcodes from 'app/Cheatcodes';
import * as Sentry from '@sentry/react';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { isAndroid } from 'app/helpers/devices';

import { store, history } from './configureStore';

import 'normalize.css/normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import 'app/css/reset.css';
import '@setproduct-ui/styles/setproduct.css';
import 'app/css/global.scss';
import 'keen-slider/keen-slider.min.css';

import { SnackbarProvider } from './components/common/Snackbar';
import EmbeddedModal from './components/EmbeddedModal';
import { isEmbeddedBrowser } from './utils';

import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import MaximusSentry from 'app/utils/maximusSentry';
import { CustomerProps } from 'app/types';
import { RedesignProvider } from 'app/utils/redesign/RedesignProvider';

MaximusSentry.init();

const App: React.FC<{ props: CustomerProps }> = ({ props }) => (
  <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>} showDialog>
    <Provider store={store}>
      <RedesignProvider>
        <SnackbarProvider>
          <ConnectedRouter history={history}>
            <Fetch userProps={props} />
            <Cheatcodes />
            {isEmbeddedBrowser() && isAndroid() && <EmbeddedModal />}
          </ConnectedRouter>
        </SnackbarProvider>
      </RedesignProvider>
    </Provider>
  </Sentry.ErrorBoundary>
);
export default App;
