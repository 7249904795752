import { createBrowserHistory, History } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router/immutable';
import { combineReducers, install } from 'redux-loop';
import { init, ModelReducers } from '@rematch/core';
import loadingPlugin from '@rematch/loading';
import { fromJS } from 'immutable';
import { mapValues } from 'lodash';

import customerReducer from 'app/reducers/customer';
import commonReducer from './reducers/common';
import { admin } from './models/admin';
import apologize from './models/apologize';
import { ThunkDispatch } from 'redux-thunk';
import { UnknownAction } from 'redux';
import { CallHistoryMethodAction } from 'connected-react-router';

export const history = createBrowserHistory();

export const createRootReducer = (history: History) =>
  ({
    common: commonReducer,
    customer: customerReducer,
    router: connectRouter(history),
  }) as ModelReducers<any>;

export function configureStore(history: History, initialState = {}) {
  return init({
    models: {
      admin,
      apologize,
    },
    plugins: [loadingPlugin()],
    redux: {
      initialState: mapValues(initialState, (a) => fromJS(a)),
      reducers: createRootReducer(history),
      enhancers: [install()],
      middlewares: [routerMiddleware(history)],
      // Uncomment to enable Redux DevTools' trace stack
      // devtoolOptions: {
      //   trace: true,
      //   traceLimit: 25,
      // },
      combineReducers,
    },
  });
}

export const store = configureStore(history);

export type RootState = ReturnType<typeof store.getState>;
export type Selector<T> = (state: RootState) => T;
export type AppDispatch = typeof store.dispatch &
  ThunkDispatch<RootState, unknown, UnknownAction | CallHistoryMethodAction<[string, any?]>>;
