import React, { useState } from 'react';
import { push } from 'connected-react-router/immutable';
import { LoginSchema, LoginWithPasswordSchema } from 'app/helpers/validators';
import { Formik, Form } from 'formik';
import { selectRouterQueryNotice } from 'app/selectors/router';
import Input from 'app/components/common/formik/Input';
import { SanitizedInput } from 'app/components/common/formik/SanitizedInput';
import { PasswordReset } from 'app/constants/Routes';
import Cookies from 'js-cookie';
import { loginWithEmail, loginWithPassword, hardRedirectRequested, NOOP } from 'app/actions/customer';
import { Button, Card, Drawer, NavBar, PageWrapper } from 'mui';
import DrawerMenu from 'app/components/common/DrawerMenu';
import { useAppDispatch, useAppSelector } from 'app/helpers/hooks';
import TrustPilotPlaceholder from 'images/trustpilot_placeholder.svg';

export const LoginHeader = () => (
  <div className="flex flex-col gap-y-4 lg:gap-y-6">
    <h4>Unlock your peak performance</h4>
    <a
      href="https://www.trustpilot.com/review/maximustribe.com"
      className="flex flex-row items-center gap-x-2 text-xs self-start"
    >
      <img src={TrustPilotPlaceholder} className="h-6" alt="TrustPilot rating" />
      <span className="text-main-dark">300+ Reviews</span>
    </a>
  </div>
);

const LoginWithLinkForm = ({ dispatch, notice, userEmail, setUserEmail, setLinkOption }) => {
  const [success, setSuccess] = useState(false);

  const onSubmit = async (values, form) => {
    const action = loginWithEmail({
      params: {
        ...values,
        anonymous_id: window.analytics?.user?.()?.anonymousId() || null,
      },
      context: {
        onSuccessActionCreator: () => {
          setSuccess(true);

          return { type: NOOP };
        },
        onFailureActionCreator: ({ json: { errors } }) => {
          if (errors) {
            for (const [key, value] of Object.entries(errors)) {
              form.setFieldError(key, value);
            }
          } else {
            form.setErrors({ api: 'Internal error' });
          }

          return { type: NOOP };
        },
      },
    });
    await dispatch(action);
  };

  const onChange = (e) => {
    if (e.target.name === 'email') {
      setUserEmail(e.target.value);
    }
  };

  return (
    <Formik initialValues={{ email: userEmail }} validationSchema={LoginSchema} onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <Form onChange={onChange} className="flex flex-col gap-y-6">
          <p>Enter your email and we’ll send you a login link so you can log in without using your password.</p>
          <SanitizedInput
            id="email"
            name="email"
            label="Email address"
            placeholder="E-mail"
            className="text-field"
            disabled={success}
            onInputChange={onChange}
          />
          {notice && !success && <p>{decodeURIComponent(notice.replace(/\+/g, '%20'))}</p>}
          {success && <p className="text-center">A login link has been sent to you via e-mail.</p>}
          <div className="flex flex-col gap-y-2">
            <Button type="submit" data-testid="request-login-link-submit" disabled={isSubmitting || success}>
              Send Login Link
            </Button>
            <Button variant="text" onClick={() => setLinkOption(false)}>
              Login with Password
            </Button>
            <Button variant="text" onClick={() => dispatch(push(PasswordReset))}>
              Reset Password
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const LoginWithPasswordForm = ({ dispatch, userEmail, setUserEmail, setLinkOption }) => {
  const onSubmit = async (values, form) => {
    const action = loginWithPassword({
      params: {
        ...values,
        anonymous_id: window.analytics?.user?.()?.anonymousId() || null,
      },
      context: {
        onSuccessActionCreator: ({ json: { path } }) => hardRedirectRequested(path),
        onFailureActionCreator: ({ json: { error } }) => {
          if (error === 'invalid') {
            form.setFieldError('email', ' ');
            form.setFieldError('password', 'Invalid e-mail or password.');
          } else {
            form.setErrors({ api: 'Internal error' });
          }

          return { type: NOOP };
        },
      },
    });
    await dispatch(action);
  };

  const onChange = (e) => {
    if (e.target.name === 'email') {
      setUserEmail(e.target.value);
    }
  };

  return (
    <Formik
      initialValues={{ email: userEmail, password: '' }}
      validationSchema={LoginWithPasswordSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, errors }) => (
        <Form onChange={onChange} className="flex flex-col gap-y-6">
          <div className="flex flex-col gap-y-4">
            <SanitizedInput
              id="email"
              name="email"
              placeholder="E-mail"
              className="text-field"
              onInputChange={onChange}
              label="Email address"
            />
            <Input id="password" name="password" type="password" placeholder="Password" label="Password" />
            {errors?.api && <p className="text-sm text-main-danger">{errors.api} 22</p>}
          </div>
          <div className="flex flex-col gap-y-2">
            <Button type="submit" data-testid="log-in-submit" disabled={isSubmitting}>
              Log In
            </Button>
            <Button variant="text" onClick={() => setLinkOption(true)} data-testid="log-in-with-link">
              Use Password-Free Login Link
            </Button>
            <Button variant="text" onClick={() => dispatch(push(PasswordReset))} data-testid="reset-password">
              Reset Password
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const Login = () => {
  const dispatch = useAppDispatch();
  const notice = useAppSelector(selectRouterQueryNotice);
  const [linkOption, setLinkOption] = useState(false);
  const [userEmail, setUserEmail] = useState(Cookies.get('email') || '');

  return (
    <Drawer sideContent={<DrawerMenu />}>
      <NavBar />
      <PageWrapper wrapperClassName="flex flex-col gap-y-6 lg:gap-y-8">
        <LoginHeader />
        <Card>
          <Card.Body>
            <h4>Member Login</h4>
            {linkOption ? (
              <LoginWithLinkForm
                dispatch={dispatch}
                notice={notice}
                userEmail={userEmail}
                setUserEmail={setUserEmail}
                setLinkOption={setLinkOption}
              />
            ) : (
              <LoginWithPasswordForm
                dispatch={dispatch}
                userEmail={userEmail}
                setUserEmail={setUserEmail}
                setLinkOption={setLinkOption}
              />
            )}
          </Card.Body>
        </Card>
      </PageWrapper>
    </Drawer>
  );
};

export default Login;
