import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { apiRequestUserCommand } from 'app/actions/customer';

const mapStateToProps = (_state) => {
  return {};
};
const Cheatcodes = ({ dispatch }) => {
  useEffect(() => {
    const useFakeId = () => {
      dispatch(apiRequestUserCommand({ cmdType: 'use_fake_id', context: {} }));
    };
    window.cheatcodes = {
      useFakeId,
    };
  }, []);
  return <span></span>;
};
export default connect(mapStateToProps)(Cheatcodes);
