import React from 'react';

const WarriorSideEffectsDetails = {
  oral_minoxidil: {
    name: 'Oral Minoxidil',
    common: {
      title: 'Common Side Effects',
      desc: 'Water retention, swelling in extremities, increased hair growth in unintended areas.',
    },
    serious_rare: {
      title: 'Serious Side Effects (Rare)',
      desc: 'Rapid heart rate, signs of fluid overload, or chest pain',
    },
  },
  oral_dutasteride: {
    name: 'Oral Dutasteride',
    common: {
      title: 'Common Side Effects',
      desc: 'Decreased libido, erectile dysfunction, reduced semen volume.',
    },
    serious_rare: {
      title: 'Serious Side Effects (Rare)',
      desc: 'Breast tenderness or lumps, allergic reactions, mood changes.',
    },
  },
  oral_finasteride: {
    common: {
      title: 'Common Side Effects',
      desc: 'Decreased libido, erectile dysfunction, reduced semen volume.',
    },
    serious_rare: {
      title: 'Serious Side Effects (Rare)',
      desc: 'Breast tenderness, depression, or mood changes.',
    },
  },
  topical_minoxidil: {
    name: 'Minoxidil+ Gel',
    common: {
      title: 'Common Side Effects',
      desc: 'Dry, itchy scalp; temporary increase in hair shedding.',
    },
    serious_rare: {
      title: 'Serious Side Effects (Rare)',
      desc: 'Scalp swelling or severe irritation, allergic reactions.',
    },
  },
  topical_dutasteride: {
    name: 'Dutasteride+ Gel',
    common: {
      title: 'Common Side Effects',
      desc: 'Mild scalp irritation; temporary hair shedding.',
    },
    less_likely: {
      title: 'Less Likely',
      desc: 'Hormonal side effects like decreased libido or mood changes due to the reduced systemic absorption of topical dutasteride',
    },
    serious_rare: {
      title: 'Serious Side Effects (Rare)',
      desc: 'Allergic reactions, persistent skin irritation.',
    },
  },
  topical_four_in_one: {
    common: {
      title: 'Common Side Effects',
      desc: 'Mild scalp irritation, dryness, or redness; temporary hair shedding.',
    },
    less_likely: {
      title: 'Less Likely',
      desc: 'Systemic effects such as decreased libido or breast tenderness due to lower systemic absorption of topical dutasteride.',
    },
    serious_rare: {
      title: 'Serious Side Effects (Rare)',
      desc: 'Allergic reactions (rash, swelling), severe skin irritation.',
    },
  },
};

const WarriorSideEffectsInfo = ({ sub_product }) => {
  if (
    sub_product === 'oral_dutasteride_oral_minoxidil' ||
    sub_product === 'oral_minoxidil_topical_dutasteride' ||
    sub_product === 'oral_dutasteride_topical_minoxidil'
  ) {
    const firstUnderscore = sub_product.indexOf('_');
    const secondUnderscore = sub_product.indexOf('_', firstUnderscore + 1);
    const firstPart = sub_product.slice(0, secondUnderscore);
    const secondPart = sub_product.slice(secondUnderscore + 1);
    const firstInfo = WarriorSideEffectsDetails[firstPart];
    const secondInfo = WarriorSideEffectsDetails[secondPart];
    return (
      <>
        <p className="bold mb-3">{firstInfo.name}</p>
        <SideEffectsSection sub_product={firstPart} />
        <p className="bold mb-3">{secondInfo.name}</p>
        <SideEffectsSection sub_product={secondPart} />
      </>
    );
  } else {
    return <SideEffectsSection sub_product={sub_product} />;
  }
};

const SideEffectsSection = ({ sub_product }) => {
  const showLessLikely = sub_product === 'topical_four_in_one' || sub_product === 'topical_dutasteride';
  const subProductHash = WarriorSideEffectsDetails[sub_product];
  return (
    <>
      <SideEffect title={subProductHash.common.title} desc={subProductHash.common.desc} />
      {showLessLikely && <SideEffect title={subProductHash.less_likely.title} desc={subProductHash.less_likely.desc} />}
      <SideEffect title={subProductHash.serious_rare.title} desc={subProductHash.serious_rare.desc} />
    </>
  );
};

const SideEffect = ({ title, desc }) => (
  <div className="mb-3">
    <p className="bold">{title}</p>
    <p>{desc}</p>
  </div>
);

export default WarriorSideEffectsInfo;
