import React, { FC, useCallback } from 'react';
import './css/HomePageSocialMedia.scss';
import { useAppDispatch, useAppSelector } from 'app/helpers/hooks';
import { selectCustomer, selectCustomerProduct } from 'app/selectors/customer';
import subProductNames from 'app/utils/subProductNames';
import { MultiMonthPlanMapping } from 'app/constants/Products';
import assetLibrary from 'app/utils/assetLibrary';
import { Badge } from 'mui';
import IcoNextFrame from 'images/ico-next-frame.svg';
import { Protocol } from './helpers/protocols';
import { push } from 'connected-react-router/immutable';
import dayjs from 'dayjs';

export type ProtocolCardItemProps = {
  title: string;
  description: string;
  url: string;
  cta?: string;
};

export type NotificationsItemProps = {
  title: string;
  description: string;
  trackingUrl: string | undefined;
};

export type ProtocolCardLink = {
  label: string;
  url: string;
};

const ProtocolCardItem: FC<ProtocolCardItemProps> = ({ title, description, cta, url }) => {
  const dispatch = useAppDispatch();

  const handleClick = useCallback(() => {
    dispatch(push(url));
  }, []);

  return (
    <div className="pt-5 p2 border-t" onClick={handleClick} role="button">
      <div className="flex flex-row flex-between items-center gap-3">
        <div>
          <p className="product-card__up-next__title">{title}</p>
          <p className="product-card__up-next__description">{description}</p>
        </div>
        {cta ? <p className="product-card__up-next__cta">{cta}</p> : <img src={IcoNextFrame} alt="Next Icon" />}
      </div>
    </div>
  );
};

const NotificationCardItem: FC<NotificationsItemProps> = ({ title, description, trackingUrl }) => {
  const handleTrackingBtnClick = (e) => {
    e.preventDefault();

    return window.open(trackingUrl, '_blank');
  };

  return (
    <div className="pt-5 p2 border-t" onClick={handleTrackingBtnClick} role="button">
      <div className="flex flex-row flex-between items-center gap-3">
        <div>
          <p className="product-card__up-next__title">{title}</p>
          <p className="product-card__up-next__description">{description}</p>
        </div>
        {trackingUrl && <p className="product-card__up-next__cta">Track Shipment</p>}
      </div>
    </div>
  );
};

const ProtocolCard: FC<{
  protocol: Protocol;
  items: ProtocolCardItemProps[];
  notifications: NotificationsItemProps[];
  link?: ProtocolCardLink;
}> = ({ protocol, items = [], notifications = [], link = null }) => {
  const product = useAppSelector((state) => selectCustomerProduct(state, protocol.slug));
  const dispatch = useAppDispatch();
  const optInChoice = product?.get('active_opt_in_choice') || product?.get('opt_in_choice');
  const customer = useAppSelector(selectCustomer);
  const isLaas = customer.get('only_lab');
  const subProduct = protocol.slug === 'king' && isLaas ? null : optInChoice?.get('sub_product');

  const subProductLabel = subProduct ? subProductNames[protocol.slug][subProduct] : `${protocol.name} Protocol`;

  const planFrequency = optInChoice?.get('multimonth_plan');
  const planLabel = `${MultiMonthPlanMapping[planFrequency] || 'Monthly'} plan`;

  const subscription = product?.get('current_subscription');
  let tryNextBilling = 'N/A';
  try {
    tryNextBilling = subscription?.get('next_billing_date_str')
      ? new Date(subscription.get('next_billing_date_str')).toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        })
      : 'N/A';
  } catch (e) {
    console.error('Error parsing next billing date', e);
  }
  const nextBilling = tryNextBilling;

  const [_, dollars, cents] = subscription?.get('friendly_name')?.match(/\$(\d+)\.(\d+)/) ?? [];
  const productImage = assetLibrary[subProduct ? `prod-${protocol.slug}-${subProduct}` : protocol.upsell.image];

  const handleClick = useCallback(() => {
    if (items.length > 0) return;

    dispatch(push(`?product=${protocol.slug}`));
  }, []);

  const handleLink = useCallback(
    (e) => {
      e.preventDefault();
      if (link) dispatch(push(link.url));
    },
    [link],
  );

  const currentStatus = () => {
    if (product.get('current_status') === 'cancelled') {
      return `Canceled at ${dayjs(product.get('cancelled_at')).format('MMMM D, YYYY')}`;
    } else if (product.get('cancelled_at')) {
      return `Cancels on ${dayjs(product.get('cancelled_at')).format('MMMM D, YYYY')}`;
    } else if (product.get('current_status') === 'paused') {
      return `Paused until ${dayjs(product.get('resume_at')).format('MMMM D, YYYY')}`;
    } else if (product.get('pause_at')) {
      return `Pauses on ${dayjs(product.get('pause_at')).format('MMMM D, YYYY')}`;
    } else {
      return 'Active';
    }
  };

  const billingDetails = (
    <div className="flex flex-row gap-2">
      <div className="flex1 flex flex-column gap-1">
        <p className="product-card__product-stats__label">{planLabel}</p>
        <p className="product-card__product-stats__value">{currentStatus()}</p>
        <p className="hidden product-card__product-stats__value">
          <sup>$</sup>
          {dollars}
          <sup>{cents}</sup>
        </p>
      </div>
      <div className="hidden flex1 flex flex-column gap-1">
        <p className="product-card__product-stats__label">Next billing</p>
        <p className="product-card__product-stats__value">{nextBilling}</p>
      </div>
    </div>
  );

  return (
    <div className="dash-card product-card" onClick={handleClick} role="button">
      <img className="product-card__product-image" src={productImage} alt={subProductLabel} />
      <div className="product-card__product-stats flex flex-column gap-3">
        <Badge variant={protocol.slug}>{protocol.name}</Badge>
        <p className="product-card__product-stats__name">{subProductLabel}</p>
        {subscription && billingDetails}
        <div className="text-right">
          {link && (
            <button className="text-xs link" onClick={handleLink}>
              {link.label}
            </button>
          )}
        </div>
      </div>
      {items.map((item) => {
        return (
          <ProtocolCardItem
            key={`dash-card-item-${protocol.slug}-${item.title}`}
            title={item.title}
            description={item.description}
            url={`?product=${protocol.slug}`}
            cta={item.cta}
          />
        );
      })}
      {notifications.map((notification) => {
        return (
          <NotificationCardItem
            key={`dash-card-item-${protocol.slug}-${notification.title}`}
            title={notification.title}
            description={notification.description}
            trackingUrl={notification.trackingUrl}
          />
        );
      })}
    </div>
  );
};

export default ProtocolCard;
