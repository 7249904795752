import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { Formik, FormikProps, Form } from 'formik';
import { useAppDispatch, useAppSelector } from 'app/helpers/hooks';
import { RootState } from 'app/configureStore';
import { InputMask } from 'app/components/common/formik/Input';
import Spinner from 'app/components/customer/Spinner';
import * as selectors from 'app/selectors/customer';
import { clearAddressFormErrors } from 'app/actions/customer';
import { PHONE_MASK, phoneDisplayFormatter } from 'app/helpers/formatters';
import { ShippingSchema } from 'app/helpers/validators';
import { apiRequestPromiseViaDispatch } from 'app/api';
import { ShippingFields } from 'app/components/customer/steps/Shipping';
import SetProductDialog from '@setproduct-ui/core/Dialog/Dialog';
import { CustomDialogCloseIcon } from 'app/components/common/NewDesignSystem/CustomDialogCloseIcon';
import { ShippingValues } from 'app/components/customer/steps/Payment/Generic/GenericShippingForm';
import { Button } from 'mui';
import SmsConsent from 'app/components/customer/Shipping/SmsConsent';

const mapStateToProps = (reduxState: RootState) => {
  const shippingAddress = selectors.selectCustomerShippingAddress(reduxState) || Map();
  const customer = selectors.selectCustomer(reduxState);
  const phone_number = customer.get('phone_number') || '';
  const receive_sms = !!customer.get('receive_sms');
  const { city = '', address_line_1 = '', address_line_2 = '', state = '', postal_code = '' } = shippingAddress.toJS();
  const initialFullName = selectors.selectCustomerFullName(reduxState);
  const initialValues = shippingAddress.merge({
    full_name: initialFullName,
    address_line_1,
    address_line_2,
    city,
    receive_sms,
    state,
    postal_code,
    phone_number: phoneDisplayFormatter(phone_number),
  });
  const initialErrors = reduxState.customer.getIn(['forms', 'update_address_form', 'errors'], {});

  return {
    initialErrors,
    initialValues,
  };
};

const ModalOutOfArea = ({ onClose }) => (
  <>
    <p className="description">
      Unfortunately, we don’t deliver to Alaska or D.C. Please enter a different address to proceed with your purchase.
    </p>
    <Button data-testid="modal-out-of-area-ok" onClick={onClose}>
      Ok
    </Button>
  </>
);

const LaasShippingForm = ({ initialValues, initialErrors, shippingFormRef, setShippingFormValid }) => {
  const INVALID_STATES = ['AK', 'DC'];
  const customerId = useAppSelector(selectors.selectCustomerId);
  const dispatch = useAppDispatch();

  const onSubmit = async (values: ShippingValues, form) =>
    apiRequestPromiseViaDispatch({
      dispatchFn: dispatch,
      path: '/api/commands',
      body: {
        type: 'update_shipping_address',
        user_id: customerId as string,
        params: {
          ...values,
          phone_number: values.phone_number!.replace(/\D/g, ''),
        },
      },
      onErrorFn: (errors) => {
        Object.entries(errors?.parsedJson?.errors).forEach((entry) => {
          const [key, value] = entry;
          form.setFieldError(key, value as string);
        });
      },
      form,
    });

  const renderOutOfAreaModal = (onClose) => (
    <SetProductDialog
      isOpen={INVALID_STATES.includes(shippingFormRef.current?.values.state)}
      onClose={onClose}
      title="Out of Area"
      text={<ModalOutOfArea onClose={onClose} />}
      className="align-left"
      showCloseModalIcon={false}
      icon={<CustomDialogCloseIcon onClose={onClose} />}
    />
  );
  return (
    <>
      <h5>Shipping Address</h5>
      <Formik
        initialValues={initialValues.toJS()}
        validationSchema={ShippingSchema}
        onSubmit={onSubmit}
        innerRef={shippingFormRef}
        validateOnMount={true}
      >
        {({ isSubmitting, values, isValid, setFieldValue }: FormikProps<ShippingValues>) => {
          setShippingFormValid(isValid);
          return (
            <Form>
              {isSubmitting ? (
                <Spinner isCenter />
              ) : (
                <>
                  <ShippingFields />
                  <div className="mb-6 mt-2">
                    <InputMask
                      id="phone_number"
                      name="phone_number"
                      onKeyUp={() => dispatch(clearAddressFormErrors())}
                      label="Phone Number"
                      displayFormatter={phoneDisplayFormatter}
                      mask={PHONE_MASK}
                      placeholder="(___) ___-___"
                      inputMode="tel"
                      initialError={initialErrors.phone_number}
                    />
                  </div>
                  <SmsConsent
                    onChange={(evt) => setFieldValue('receive_sms', evt.target.checked)}
                    checked={values.receive_sms ?? false}
                  />
                </>
              )}
              {renderOutOfAreaModal(() => setFieldValue('state', ''))}
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default connect(mapStateToProps)(LaasShippingForm);
