import React from 'react';
import { push } from 'connected-react-router/immutable';
import * as actions from 'app/actions/customer';
import * as selectors from 'app/selectors/customer';
import './css/PreQualified.scss';
import { useAppDispatch, useAppSelector } from 'app/helpers/hooks';
import { selectFurthestProductStepPath, selectCurrentIntakeProduct } from 'app/selectors/customer';
import { Card, Button, PageWrapper } from 'mui';

const PreQualified = ({ step }) => {
  const dispatch = useAppDispatch();
  const intake = useAppSelector(selectors.selectCurrentIntake);
  const productName = useAppSelector(selectCurrentIntakeProduct).toLowerCase();

  const onSuccessActionCreator = (_, stateAfterSuccess) =>
    push(selectFurthestProductStepPath({ customer: stateAfterSuccess }, productName));
  const submitPreQualified = () => {
    const action = actions.apiRequestUserCommand({
      cmdType: 'answer_questions',
      params: { [step]: true, intake: intake.get('name') },
      context: { onSuccessActionCreator },
    });
    dispatch(action);
  };

  return (
    <PageWrapper>
      <Card className="pre-qualified-card pt-[4rem]">
        <Card.Body>
          <h3>You’re pre-qualified!</h3>
          <p>You&apos;ve been pre-qualified based on your responses.</p>
          <p>The next step is to confirm your lab selection, review your order details and complete checkout.</p>
          <p>After checkout your baseline lab work will be sent to your Maximus doctor for final approval.</p>
          <p>
            No need to sweat it. Most are approved for treatment, but rest assured that you will be refunded if you
            aren't.
          </p>
          <Button onClick={submitPreQualified} data-testid="submit-pre-qualified-step">
            Next
          </Button>
        </Card.Body>
      </Card>
    </PageWrapper>
  );
};

export default PreQualified;
