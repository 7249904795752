import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import LabImage from 'images/LabImage.png';
import SetProductDialog from '@setproduct-ui/core/Dialog';
import { CustomDialogCloseIcon } from 'app/components/common/NewDesignSystem/CustomDialogCloseIcon';
import Input from 'app/components/common/formik/Input';
import { getCSRF } from 'app/api';
import axios from 'axios';
import { RegistrationSchemaLabEmail } from 'app/helpers/validators';
import { hardRedirectRequested, loginWithPassword, NOOP } from 'app/actions/customer';
import * as Routes from 'app/constants/Routes';
import { useAppDispatch, useAppSelector } from 'app/helpers/hooks';
import * as routerSelectors from 'app/selectors/router';
import { LaaSType } from 'app/constants/Products';
import { Button, Card } from 'mui';

const ModalBodyBiomarkers = ({ setShowModal, base_t_kit }) => (
  <>
    <p>We analyze these key biomarkers to give you a complete picture of your testosterone health:</p>
    <ul className="disc-list">
      <li>
        <b>Free Testosterone</b>: Active testosterone your body can actually use.
      </li>
      <li>
        <b>Total Testosterone</b>: Total amount of testosterone in your body, both active and inactive.
      </li>
      <li>
        <b>SHBG (Sex Hormone-Binding Globulin)</b>: Protein that binds to testosterone, affecting how much is available
        for your body to use.
      </li>
      <li>
        <b>E2 (estradiol)</b>: Form of estrogen that helps balance testosterone and overall hormonal health.
      </li>
      <li>
        <b>LH (luteinizing hormone)</b>: Regulates testosterone production.
      </li>
      <li>
        <b>FSH (Follicle-Stimulating Hormone)</b>: Important for sperm production and reproductive health.
      </li>
      {base_t_kit && (
        <li>
          <b>TPSA</b>: Measures prostate-specific antigen to monitor prostate health.
        </li>
      )}

      {!base_t_kit && (
        <li>
          <b>PSA (Prostate-Specific Antigen)</b>: Marker used to monitor prostate health.
        </li>
      )}
      <li>
        <b>ALT</b>: Liver enzyme that helps ensure your liver is functioning properly.
      </li>
      {!base_t_kit && (
        <li>
          <b>GGT (Gamma-Glutamyl Transferase)</b>: Liver enzyme involved in detoxification and bile flow; often elevated
          in liver stress or bile duct issues.
        </li>
      )}
      <li>
        <b>Vitamin D</b>: Plays a role in testosterone production and overall energy levels.
      </li>
      {!base_t_kit && (
        <>
          <li>
            <b>Hematocrit</b>: Measures the percentage of red blood cells in your blood, which helps carry oxygen
            throughout your body.
          </li>
          <li>
            <b>Creatinine</b>: By-product of muscle metabolism that helps assess kidney function.
          </li>
          <li>
            <b>HbA1c</b>: Three-month average of blood sugar levels, used to monitor glucose control.
          </li>
        </>
      )}
    </ul>
    <Button
      type="button"
      data-testid="modal-biomarkers-got-it"
      className="button-next"
      disabled={false}
      onClick={() => setShowModal(false)}
    >
      OK
    </Button>
  </>
);

const EnterEmailStep = ({ initialValues, onSubmitNextStep }) => {
  const msoTermsOfUseLink = '/terms-of-use';
  const msoPrivacyPolicyLink = '/privacy-policy';
  const [showModal, setShowModal] = useState(false);
  const [emailExist, setEmailExist] = useState(false);
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [onlyLabEligible, setOnlyLabEligible] = useState(false);
  const dispatch = useAppDispatch();
  const sub_product = useAppSelector((state) => routerSelectors.selectRouterQuery(state).get('sub_product'));

  const base_t_kit = sub_product == LaaSType.BaseTestKit;

  const onSubmit = async (values, form) => {
    if (!showPasswordField) {
      const response = await axios.post(
        '/api/guests/validate_email_uniqueness',
        {
          email: values.email,
        },
        { headers: { 'X-CSRF-Token': getCSRF() } },
      );

      setEmailExist(!response.data.unique);
      setOnlyLabEligible(response.data.only_lab_eligible);

      if (response.data.unique) {
        onSubmitNextStep(values);
      }
    } else {
      const action = loginWithPassword({
        params: {
          ...values,
          anonymous_id: window.analytics?.user?.()?.anonymousId() || null,
        },
        context: {
          onSuccessActionCreator: ({ json: { path } }) =>
            onlyLabEligible
              ? hardRedirectRequested(
                  sub_product ? `${Routes.LabCheckout}?sub_product=${sub_product}` : Routes.LabCheckout,
                )
              : hardRedirectRequested(path + '?show_only_lab_modal=true'),
          onFailureActionCreator: ({ json: { error } }) => {
            if (error === 'invalid') {
              form.setFieldError('password', 'Invalid password.');
            } else {
              form.setErrors({ api: 'Internal error' });
            }

            return { type: NOOP };
          },
        },
      });

      await dispatch(action);
    }
  };

  const ModalHeader = (
    <>
      <h3 className="lg:hidden">Biomarkers We Test</h3>
      <h5 className="hidden lg:block lg:visible">Biomarkers We Test</h5>
    </>
  );

  const renderBiomarkersModal = () => (
    <SetProductDialog
      isOpen={showModal}
      onClose={() => setShowModal(false)}
      title={ModalHeader}
      text={<ModalBodyBiomarkers setShowModal={setShowModal} base_t_kit={base_t_kit} />}
      className="align-left"
      showCloseModalIcon={true}
      icon={<CustomDialogCloseIcon onClose={() => setShowModal(false)} />}
    />
  );

  return (
    <Formik
      initialValues={{
        ...initialValues,
      }}
      onSubmit={onSubmit}
      validationSchema={RegistrationSchemaLabEmail}
    >
      {({ isSubmitting, setFieldValue, isValid }) => (
        <Form>
          <Card>
            <Card.Body>
              <div className="text-center">
                <img className="inline" src={LabImage} alt="" />
              </div>

              <h4>Testosterone {base_t_kit ? 'Base' : 'Advanced'} Test</h4>

              <p>
                Your <b className="bold">Testosterone Test Kit</b> arrives fast with free Priority Shipping, so you can
                check your key hormones quickly and accurately.
              </p>

              <button
                className="link"
                onClick={(e) => {
                  e.preventDefault();
                  setShowModal(true);
                }}
              >
                Learn about biomarkers we test for
              </button>

              <hr />

              <p>Please enter your email address which will allow you to access your results quickly.</p>

              <div className="flex flex-col gap-y-2">
                <Input
                  onChange={(e) => setFieldValue('email', e.target.value.trim())}
                  id="email"
                  label="Email Address"
                  name="email"
                  placeholder="Enter Email Address"
                  className="text-field"
                  disabled={emailExist}
                  errorText={
                    emailExist &&
                    !showPasswordField && (
                      <>
                        This email already exists. Would you like to{' '}
                        <button className="link" onClick={() => setShowPasswordField(true)}>
                          Log in
                        </button>
                        ?
                      </>
                    )
                  }
                />

                {showPasswordField && (
                  <Input
                    label={'Password'}
                    onChange={(e) => setFieldValue('password', e.target.value.trim())}
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Enter Password"
                  />
                )}
              </div>

              <Button
                disabled={isSubmitting || !isValid || (emailExist && !showPasswordField)}
                type="submit"
                data-testid="registration-step-0-submit"
                className="button-next"
              >
                {showPasswordField ? 'Log In' : 'Next'}
              </Button>

              <p className="text-xs">
                By clicking this button, I acknowledge that I have read, understand, and agree to the{' '}
                <a rel="noreferrer" href={msoTermsOfUseLink} target="_blank">
                  Terms of Use
                </a>
                {' and '}
                <a rel="noreferrer" href={msoPrivacyPolicyLink} target="_blank">
                  Privacy Policy
                </a>
                .
              </p>
              {renderBiomarkersModal()}
            </Card.Body>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default EnterEmailStep;
