import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import 'app/css/NewDesignSystem.scss';
import './css/Messages.scss';
import { useParams } from 'react-router-dom';
import AvatarOther from 'images/avatar-other.svg';
import AvatarYou from 'images/avatar-you.svg';
import dayjs from 'dayjs';
import { FormField, Button, Heading } from 'mui';
import axios from 'axios';
import Spinner from 'app/components/customer/Spinner';
import { getCSRF } from 'app/api';

export interface IMessageAttachment {
  contentType: string;
  title: string;
  url: string;
}

export interface IMessage {
  id: string;
  message: string;
  sender: string;
  timestamp: string;
  unread: boolean;
  attachment: IMessageAttachment | null;
}

const MessageCard: FC<{ message: IMessage; isLast: boolean }> = ({ message, isLast }) => {
  const cardRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      async (entries) => {
        if (entries[0].isIntersecting) {
          try {
            if (message.unread) {
              await axios.post(
                '/api/messages/mark_read',
                { message_id: message.id },
                { headers: { 'X-CSRF-Token': getCSRF() } },
              );
            }
            observer.disconnect();
          } catch (error) {
            console.error('Error sending mark as read request', error);
          }
        }
      },
      { threshold: 0, rootMargin: '0px 0px -170px 0px' },
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, [message.id]);

  return (
    <div ref={cardRef} className="relative">
      <div className="flex-row flex gap-4 mb-4">
        <div>
          <img src={message.sender == 'You' ? AvatarYou : AvatarOther} alt="avatar" />
        </div>
        <div className="flex flex-column gap-3 flex-grow">
          <div className="flex flex-row justify-between mt-1">
            <Heading headingLevel="h5">{message.sender}</Heading>
            <p className="small">
              {dayjs(message.timestamp).isSame(dayjs(), 'day')
                ? dayjs(message.timestamp).format('hh:mm A')
                : dayjs(message.timestamp).format('MM/DD/YYYY')}
            </p>
          </div>
          <p>{message.message}</p>
          {message.attachment && (
            <p className="text-xs">
              Attachment:{' '}
              <a href={message.attachment.url} target="_blank" rel="noopener noreferrer">
                {message.attachment.title}
              </a>
            </p>
          )}
        </div>
      </div>
      {!isLast && (
        <div className="mx-4 mb-4">
          <hr />
        </div>
      )}
      {message.unread && <div className="red-circle"></div>}
    </div>
  );
};

const MessagesThread = () => {
  const params = useParams<{ thread_id: string }>();
  const threadId = params['thread_id'];
  const [showReply, setShowReply] = useState<boolean>(false);
  const [messages, setMessages] = useState<IMessage[] | null>(null);
  const [to, setTo] = useState<string>('Maximus Provider');
  const [message, setMessage] = useState<string>('');
  const [sending, setSending] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const sendMessage = async () => {
    setSending(true);
    setError(null);
    try {
      const response = await axios.post(
        '/api/messages/reply',
        {
          message: message,
          thread_id: threadId,
        },
        { headers: { 'X-CSRF-Token': getCSRF() } },
      );
      const newMessage = response.data['message'];
      if (newMessage) {
        setMessages((prevMessages) => [newMessage, ...(prevMessages ?? [])]);
        setMessage('');
        setShowReply(false);
      } else {
        setError('Unable to send the message. Please try again later.');
      }
    } catch (error) {
      setError('Failed to send message. Please try again.');
    } finally {
      setSending(false);
    }
  };

  const clickReply = useCallback(() => {
    setShowReply(true);
  }, [setShowReply]);

  const fetchMessages = useCallback(async () => {
    const response = await axios.get(`/api/messages/thread/${threadId}`);
    if (response.data['messages'] && response.data['messages'].length === 0) {
      // Error...
      return;
    }
    setMessages(response.data['messages']);
    setTo(response.data['thread']['title']);
  }, [threadId]);

  useEffect(() => {
    fetchMessages();
  }, []);

  if (messages === null) return <Spinner isCenter />;

  return (
    <>
      <div className="centered flex-column p-5">
        <div className="card p-4 relative">
          {showReply && (
            <div className="p-3">
              <FormField
                label={`Reply to: ${to || 'Maximus Provider'}`}
                multiline
                placeholder=""
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                rows={4}
                disabled={sending}
                errorText={error}
                autoFocus={true}
              />
              <Button
                onClick={sendMessage}
                className="mt-5"
                variant="primary"
                disabled={sending || message.trim() === ''}
              >
                Send Reply
              </Button>
              <Button variant="text" disabled={sending} onClick={() => setShowReply(false)}>
                Cancel
              </Button>
              <hr className="my-3" />
            </div>
          )}
          {messages === null ? (
            <Spinner isCenter />
          ) : (
            messages.map((message, index) => (
              <MessageCard key={message.id} message={message} isLast={index === messages.length - 1} />
            ))
          )}
        </div>
        {!showReply && <div className="my-16" />}
      </div>
      {!showReply && (
        <div className="messages-footer align-items-center">
          <div className="centered flex-column px-10 py-8">
            <Button variant="outline" onClick={clickReply}>
              Reply
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default MessagesThread;
