import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Login } from 'app/constants/Routes';
import { useAppDispatch } from 'app/helpers/hooks';
import { LoginSchema } from 'app/helpers/validators';
import { Formik, Form } from 'formik';
import { SanitizedInput } from 'app/components/common/formik/SanitizedInput';
import Cookies from 'js-cookie';
import { passwordReset, NOOP } from 'app/actions/customer';
import { Card, Button, Drawer, NavBar, PageWrapper } from 'mui';
import DrawerMenu from 'app/components/common/DrawerMenu';
import { LoginHeader } from './Login';

const PasswordResetForm = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [success, setSuccess] = useState(false);

  const onSubmit = async (values, form) => {
    const action = passwordReset({
      params: values,
      context: {
        onSuccessActionCreator: () => {
          setSuccess(true);

          return { type: NOOP };
        },
        onFailureActionCreator: () => {
          form.setErrors({ api: 'Internal error' });

          return { type: NOOP };
        },
      },
    });
    await dispatch(action);
  };

  return (
    <Formik initialValues={{ email: Cookies.get('email') || '' }} validationSchema={LoginSchema} onSubmit={onSubmit}>
      {({ isSubmitting, errors }) => (
        <Form className="flex flex-col gap-y-6 lg:gap-y-8">
          <SanitizedInput
            id="email"
            name="email"
            placeholder="Enter email"
            label="Email address"
            className="text-field"
            disabled={success}
          />
          {errors?.api && <p className="text-center text-main-danger">{errors.api}</p>}
          {success && <p className="text-center">A reset link has been sent to you via e-mail.</p>}
          <div className="flex flex-col gap-y-2">
            <Button type="submit" data-testid="reset-password-submit" disabled={isSubmitting || success}>
              Reset Password
            </Button>
            <Button variant="text" onClick={() => history.push(Login)}>
              Back to Member Login
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const PasswordReset = () => (
  <Drawer sideContent={<DrawerMenu />}>
    <NavBar />
    <PageWrapper wrapperClassName="flex flex-col gap-y-6 lg:gap-y-8">
      <LoginHeader />
      <Card>
        <Card.Body>
          <h4>Reset Password</h4>
          <p>Enter your email and we’ll send you a link to reset your password.</p>
          <PasswordResetForm />
        </Card.Body>
      </Card>
    </PageWrapper>
  </Drawer>
);

export default PasswordReset;
