import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router/immutable';
import * as selectors from 'app/selectors/customer';
import { Button, Card, Heading, IconList, PageWrapper } from 'mui';
import UserCircleIcon from 'images/icons/user_circle.svg';
import UserPlusIcon from 'images/icons/user_plus.svg';

const mapStateToProps = (state) => {
  const nextStepAction = push(selectors.selectNextStepPath(state));

  return {
    nextStepAction,
  };
};

const iconComponent = (icon) => () => <img src={icon} alt="" />;

const Checkpoint = ({ nextStepAction, dispatch }) => {
  return (
    <PageWrapper wrapperClassName="flex flex-col gap-y-6 lg:gap-y-8">
      <div>
        <Heading headingLevel="p" label>
          Thank you
        </Heading>
        <h3>Your Order #3214</h3>
        <span className="mt-2">Almost Done!</span>
      </div>
      <Card>
        <Card.Body>
          <h5>Two quick steps to finish</h5>
          <IconList
            items={[
              {
                icon: iconComponent(UserCircleIcon),
                title: (
                  <div>
                    Verify ID and location <span className={'text-main-danger font-normal'}>(required)</span>
                  </div>
                ),
                text: 'Please have your government issued ID ready. We are required to verify that we can provide high quality care in your area.',
              },
              {
                icon: iconComponent(UserPlusIcon),
                title: (
                  <div>
                    Leave a note for your doctor <span className={'text-main-danger font-normal'}>(required)</span>
                  </div>
                ),
                text: 'This is your opportunity to start a conversation with your doctor so they can address any questions or concerns you may have.',
              },
            ]}
          />
          <Button onClick={() => dispatch(nextStepAction)} testid="checkpoint_continue">
            Finish Up
          </Button>
        </Card.Body>
      </Card>
    </PageWrapper>
  );
};

export default connect(mapStateToProps)(Checkpoint);
