import React from 'react';
import { apologizeForSelector } from 'app/selectors/apologize';
import { useAppSelector } from 'app/helpers/hooks';
import { Redirect } from 'react-router';
import { Root } from 'app/constants/Routes';
import FailureState from '../customer/dashboard/FailureState';
import DrawerMenu from 'app/components/common/DrawerMenu';
import { Drawer, NavBar } from 'mui';

// eslint-disable-next-line react/display-name
export default () => {
  const apologizeFor = useAppSelector(apologizeForSelector);

  if (!apologizeFor) {
    return <Redirect to={Root} />;
  }

  return (
    <Drawer sideContent={<DrawerMenu loggedIn={false} />}>
      <NavBar />
      <FailureState state={apologizeFor} />
    </Drawer>
  );
};
