import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router/immutable';
import * as actions from 'app/actions/customer';
import * as selectors from 'app/selectors/customer';
import * as Routes from 'app/constants/Routes';
import protocolNames from '../../../utils/protocolNames';
import { AvailableProducts } from '../../../constants/Products';
import { PageWrapper, Card, IconList, Button } from 'mui';
import StocksListIcon from 'images/stocks-list-icon.svg';

const benefits = [
  'Stronger erections; longer endurance',
  'Greater sexual satisfaction. Go multiple times',
  'Better muscle pumps; less muscle fatigue',
  'Enhanced memory, attention, & processing speed',
];
const access = [
  'Prescription medication tablets (if qualified)',
  'Licensed doctor consultations',
  'Health coaching & community',
  'Priority shipping for meds',
];

const mapStateToProps = (state) => ({
  intake: selectors.selectCurrentIntake(state),
});

const StockIcon = () => <img src={StocksListIcon} alt="" />;
const LoverOnboardingAcknowledgement = ({ step, intake, dispatch }) => {
  const action = actions.apiRequestUserCommand({
    cmdType: 'answer_questions',
    params: { [step]: true, intake: intake.get('name') },
    context: {
      onSuccessActionCreator: ({ json: { entity } }) =>
        push(
          Routes.createIntake(
            intake.get('name'),
            entity.maximus_products.lover.intakes[0].steps.filter((step) => !step.completed)[0].url_friendly_name,
          ),
        ),
    },
  });

  const onClick = () => {
    dispatch(action);
  };

  const iconsList = (elements) => {
    const items = elements.map((item, _index) => ({
      text: item,
      icon: StockIcon,
    }));

    return <IconList items={items} />;
  };

  return (
    <PageWrapper wrapperClassName="flex flex-col gap-y-4 lg:gap-y-6">
      <div className="ml-10 sm:ml-5">
        <h3 className="mb-2">{protocolNames[AvailableProducts.Lover]} Protocol</h3>
        <p>
          {protocolNames[AvailableProducts.Lover]} comes in a convenient, orally disintegrating tablet that stimulates
          your body{"'"}s own natural blood flow. This combination is fast-acting and long-lasting so you perform your
          best in the bedroom, boardroom, and the gym.
        </p>
      </div>
      <Card>
        <Card.Body>
          <h5>Blood flow benefits include:</h5>
          {iconsList(benefits)}
          <hr />
          <h5 className="text-bold">With your subscription you have access to:</h5>
          {iconsList(access)}
          <Button variant="primary" onClick={onClick} data-testid="next-step">
            Start My Journey
          </Button>
        </Card.Body>
      </Card>
    </PageWrapper>
  );
};

export default connect(mapStateToProps)(LoverOnboardingAcknowledgement);
