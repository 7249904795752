import React, { useRef, useState, useMemo, useCallback, ComponentProps, FC } from 'react';
import { useAppDispatch, useAppSelector } from 'app/helpers/hooks';
import cx from 'classnames';
import * as actions from 'app/actions/customer';
import * as selectors from 'app/selectors/customer';
import { Formik, Form } from 'formik';
import '../css/ReferralSourceModal.scss';
import '../css/WhyCancelModal.scss';
import TextArea from 'app/components/common/formik/TextArea';
import REASONS from 'app/constants/cancellation-reasons.json';
import { FullscreenModal } from 'app/components/common/FullscreenModal';
import protocolNames from 'app/utils/protocolNames';
import { Button, Badge, Card, PageWrapper, ProgressBar as MxPb, IntakeCheckbox, IntakeRadio } from 'mui';
import { trackEvent } from 'app/utils/useCommonTracking';

const ModalBody = ({
  productName,
  setSuccessOpen,
  setDialogOpen,
  applyCommand,
}: {
  productName: 'king' | 'lover' | 'warrior' | 'magician';
  setDialogOpen: (arg: boolean) => void;
  setSuccessOpen: (arg: boolean) => void;
  applyCommand?: (cmdType: string, values?: any) => void;
  fullScreenModalRef: any;
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const customerId = useAppSelector(selectors.selectCustomerId);
  const productsImm = useAppSelector(selectors.selectCustomerProducts);
  const product = productsImm?.get(productName);
  const monthly_installment_plan = !!product?.get('active_monthly_installment_plan');
  const questionsCount = monthly_installment_plan ? 4 : 3;
  const nextSubscriptionDate = product?.get('current_subscription')?.get('next_subscription_date_str');

  const dispatch = useAppDispatch();
  const defaultValues = useMemo(
    () => REASONS[productName].reduce((ac, a: any) => ({ ...ac, [a.value]: a.default || false }), {} as any),
    [productName],
  );

  const submit = (data) => {
    const cancelParams = {
      type: 'cancel_account',
      user_id: customerId,
      params: {
        product_name: productName,
      },
    };
    const cancelAction = actions.apiRequestCommand({
      params: cancelParams,
      context: {},
    });
    dispatch(cancelAction);

    const cmdType = 'answer_questions';
    const params = { cancel_reasons: data, product_name: productName };

    if (!applyCommand) {
      dispatch(
        actions.apiRequestUserCommand({
          cmdType,
          params,
          context: {},
        }),
      );
    } else {
      applyCommand(cmdType, params);
    }

    setDialogOpen(false);
    setSuccessOpen(true);
  };

  function getQuestionsForQueue(questionsQueue, values, baseQ) {
    baseQ.forEach((q) => {
      for (let i = 0; i < q.questions.length; i++) {
        const question = q.questions[i];
        if (values[question.value] && question.more_info) {
          questionsQueue.push(question.more_info);

          getQuestionsForQueue(questionsQueue, values, question.more_info);
        }
      }
    });
  }

  const baseQuestions = REASONS[productName];
  const initialQuestion = {
    number: `Question: 1 of ${questionsCount}`,
    questions: [
      {
        title: <>Why are you canceling your {protocolNames[productName]} subscription (select all that apply):</>,
        questions: [
          ...REASONS[productName],
          {
            label: 'Other',
            value: 'other',
            details: true,
          },
        ],
      },
    ],
  };

  const [currentQuestions, setCurrentQuestions] = useState([initialQuestion]);
  const nextStep = useCallback(
    (values, setValues) => {
      const questionsQueue: any = [];
      if (baseQuestions) {
        getQuestionsForQueue(questionsQueue, values, [
          {
            questions: baseQuestions,
          },
        ]);
      }
      const idx = questionsQueue.indexOf(currentQuestions);

      if (currentStep === 1 && values.too_expensive) {
        trackEvent('InAppCancelSubscriptionTooExpensiveNext', { product_name: productName });
      }

      if (questionsQueue[idx + 1]) {
        const q = questionsQueue[idx + 1];
        setCurrentQuestions(q);
        const val = { ...values };
        q.forEach((iq) => {
          iq.questions.forEach((qv) => {
            qv.questions.forEach((question) => {
              val[question.value] = typeof question.default === 'string' ? question.default : false;
            });
          });
        });
        setValues(val);
      } else {
        setCurrentStep((step) => step + 1);
      }
    },
    [currentQuestions, currentStep],
  );

  const previousStep = useCallback(() => {
    setCurrentQuestions([initialQuestion]);
    setCurrentStep((step) => step - 1);
  }, [currentQuestions]);

  const stepBadge = (step: number) => {
    return (
      <>
        <MxPb progress={(100 * step) / questionsCount} />
        <div className="flex flex-row justify-between mt-6">
          <Badge variant={productName}>{protocolNames[productName]}</Badge>
          <span>
            {step} of {questionsCount}
          </span>
        </div>
      </>
    );
  };

  return (
    <div className="referral-source-modal__body">
      <div>
        <h4 className="mb-6">{`Cancel ${protocolNames[productName]} Protocol`}</h4>

        <div className="referral-source-modal__options">
          <Formik
            initialValues={{
              ...defaultValues,
              other: false,
              expected_lab_results: '123',
              yes_hormone_optimizing: false,
              no_hormone_optimizing: false,
              open_to_discuss: false,
              other_reason: null,
              not_open_to_discuss: false,
            }}
            onSubmit={submit}
          >
            {({ values, handleSubmit, setValues }) => (
              <Form onSubmit={handleSubmit}>
                {currentStep > 0 && (
                  <div className="mb-4">
                    <p>Please answer these three questions to complete your cancelation</p>
                  </div>
                )}
                <Card>
                  <Card.Body>
                    {currentStep === 1 && (
                      <div>
                        {stepBadge(currentStep)}
                        {currentQuestions.map((currentQuestion, index) => (
                          <div className="question-wrapper mt-6" key={index}>
                            {currentQuestion.questions.map((q: any, i) => (
                              <div key={i} className={cx({ mt32: !!i })}>
                                {q.title && <h6 className="my-6">{q.title}</h6>}
                                <div className="health-questions">
                                  {q.questions.map((question) => {
                                    if (question.value === 'expected_lab_results') {
                                      return (
                                        <TextArea
                                          value={null}
                                          defaultValue=""
                                          key={question.value}
                                          success={null}
                                          id="expected_lab_results"
                                          name="expected_lab_results"
                                          placeholder="Enter your answer here"
                                        />
                                      );
                                    }

                                    return question.details ? (
                                      <React.Fragment key={question.value}>
                                        <div
                                          className={cx('health-questions__item mt-4', {
                                            other: q.questions.length % 2 === 1,
                                          })}
                                          key={question.value}
                                        >
                                          <IntakeCheckbox
                                            key={question.value}
                                            id={question.value}
                                            name={question.value}
                                            label={`${question.label} (Please describe)`}
                                            className="hs2-checkbox"
                                            checked={values[question.value]}
                                            onChange={() => {
                                              setValues({
                                                ...values,
                                                [question.value]: !values[question.value],
                                              });
                                            }}
                                          />
                                        </div>
                                        <div
                                          className={cx('describe_reason', {
                                            'display-none': !values[question.value],
                                          })}
                                        >
                                          <p>Please describe your reason </p>
                                          <TextArea
                                            success={null}
                                            id={`${question.value}_reason`}
                                            name={`${question.value}_reason`}
                                            placeholder="Enter your answer here"
                                          />
                                        </div>
                                      </React.Fragment>
                                    ) : (
                                      <div className="health-questions__item mt-4" key={question.value}>
                                        <IntakeCheckbox
                                          key={question.value}
                                          id={question.value}
                                          name={question.value}
                                          label={question.label}
                                          className="hs2-checkbox"
                                          checked={values[question.value]}
                                          onChange={() => {
                                            setValues({
                                              ...values,
                                              [question.value]: !values[question.value],
                                            });
                                          }}
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            ))}
                            <hr className="my-6" />
                            <div className="mt-6 flex flex-col gap-y-2">
                              <Button
                                variant="primary"
                                data-testid="referral-source-next"
                                onClick={(e) => {
                                  e.preventDefault();
                                  nextStep(values, setValues);
                                }}
                                disabled={
                                  !currentQuestion.questions.some(
                                    (q) => q.questions && q.questions.some((question) => values[question.value]),
                                  )
                                }
                              >
                                Next
                              </Button>
                              <Button
                                variant="text"
                                data-testid="referral-source-back"
                                onClick={() => setDialogOpen(false)}
                              >
                                Back
                              </Button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}

                    {currentStep === 2 && (
                      <div className="question-wrapper">
                        {stepBadge(currentStep)}
                        <h6 className="mb-4 mt-6">Are you planning use other products instead of Maximus?</h6>
                        <div className="flex flex-col gap-y-2">
                          <div className="health-questions__item radio-style mt-2">
                            <IntakeRadio
                              id="yes_hormone_optimizing"
                              name="yes_hormone_optimizing"
                              label="Yes (Please describe)"
                              checked={values.yes_hormone_optimizing}
                              onChange={() => {
                                setValues({ ...values, yes_hormone_optimizing: true, no_hormone_optimizing: false });
                              }}
                            />
                          </div>
                          <div className="health-questions__item radio-style mt-4">
                            <IntakeRadio
                              onChange={() => {
                                setValues({
                                  ...values,
                                  yes_hormone_optimizing: false,
                                  yes_hormone_optimizing_description: null,
                                  no_hormone_optimizing: true,
                                });
                                nextStep(values, setValues);
                              }}
                              id="no_hormone_optimizing"
                              label="No"
                              name="no_hormone_optimizing"
                              checked={values.no_hormone_optimizing}
                            />
                          </div>
                          <div className={cx('describe_reason', { 'display-none': !values.yes_hormone_optimizing })}>
                            <p>Please tell us what product you are using and why</p>
                            <TextArea
                              success={null}
                              id="yes_hormone_optimizing_description"
                              name="yes_hormone_optimizing_description"
                              placeholder="Enter your answer here"
                            />
                          </div>
                        </div>
                        <hr className="my-6" />
                        <div className="mt-6 flex flex-col gap-y-2">
                          {values.yes_hormone_optimizing && (
                            <Button
                              variant="primary"
                              data-testid="referral-source-next"
                              onClick={() => nextStep(values, setValues)}
                            >
                              Next
                            </Button>
                          )}
                          <Button variant="text" data-testid="referral-source-next" onClick={() => previousStep()}>
                            Back
                          </Button>
                        </div>
                      </div>
                    )}

                    {currentStep === 3 && (
                      <div className="question-wrapper">
                        {stepBadge(currentStep)}
                        <h6 className="my-6">
                          Are you open to discussing your experience with one of our product specialists?
                        </h6>
                        <div className="flex flex-col gap-y-2">
                          <div className="health-questions__item radio-style">
                            <IntakeRadio
                              id="open_to_discuss"
                              label="Yes, a product specialist may contact me"
                              name="open_to_discuss"
                              checked={values.open_to_discuss}
                              onChange={() => {
                                setValues({ ...values, not_open_to_discuss: false, open_to_discuss: true });
                                handleSubmit();
                              }}
                            />
                          </div>
                          <div className="health-questions__item radio-style mt-2">
                            <IntakeRadio
                              id="not_open_to_discuss"
                              label="No"
                              name="not_open_to_discuss"
                              onChange={() => {
                                setValues({ ...values, not_open_to_discuss: true, open_to_discuss: false });
                                monthly_installment_plan ? nextStep(values, setValues) : handleSubmit();
                              }}
                            />
                          </div>
                        </div>
                        <hr className="my-6" />
                        <div className="mt-6 flex flex-col gap-y-2">
                          <Button
                            variant="primary"
                            data-testid="referral-source-next"
                            onClick={() => (monthly_installment_plan ? nextStep(values, setValues) : handleSubmit())}
                          >
                            {monthly_installment_plan ? 'Next' : 'Cancel subscription'}
                          </Button>
                          <Button variant="text" data-testid="referral-source-next" onClick={() => previousStep()}>
                            Back
                          </Button>
                        </div>
                      </div>
                    )}
                    {currentStep === 4 && (
                      <div className="question-wrapper">
                        {stepBadge(currentStep)}
                        <p className="mt-6">
                          By canceling, your plan will stay active until {nextSubscriptionDate}, but won’t renew after
                          that. Monthly payments will continue until the end of this cycle.
                        </p>
                        <div className="flex flex-col gap-y-2">
                          <div className="mt-6 flex flex-col gap-y-2">
                            <Button variant="primary" data-testid="referral-source-next" onClick={() => handleSubmit()}>
                              Continue to cancel
                            </Button>
                          </div>
                          <p>
                            If you have further questions, you can reach out to customer service at{' '}
                            <a href="mailto:support@maximustribe.com">support@maximustribe.com</a>.
                          </p>
                        </div>
                        <div className="mt-6 flex flex-col gap-y-2">
                          <Button variant="text" data-testid="referral-source-next" onClick={() => previousStep()}>
                            Back
                          </Button>
                        </div>
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

const WhyCancelModal: FC<{
  productName: ComponentProps<typeof ModalBody>['productName'];
  openCancelReasons?: boolean;
  setOpenCancelReasons: ComponentProps<typeof ModalBody>['setDialogOpen'];
  setOpenCancelSuccess: ComponentProps<typeof ModalBody>['setSuccessOpen'];
  applyCommand?: ComponentProps<typeof ModalBody>['applyCommand'];
}> = ({ productName, openCancelReasons, setOpenCancelReasons, setOpenCancelSuccess, applyCommand }) => {
  const fullScreenModalRef = useRef();

  return (
    <FullscreenModal
      isOpen={openCancelReasons}
      onClose={() => setOpenCancelReasons(false)}
      text={
        <PageWrapper>
          <ModalBody
            productName={productName}
            setSuccessOpen={setOpenCancelSuccess}
            setDialogOpen={setOpenCancelReasons}
            applyCommand={applyCommand}
            fullScreenModalRef={fullScreenModalRef}
          />
        </PageWrapper>
      }
    />
  );
};

export default WhyCancelModal;
