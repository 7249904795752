import React, { FC, ReactNode, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { Form, Formik, FormikProps } from 'formik';
import { useAppDispatch, useAppSelector } from 'app/helpers/hooks';
import { ImmutableMap } from 'app/types/admin';
import { RootState } from 'app/configureStore';
import { CustomerShippingAddress } from 'app/types';
import { InputMask } from 'app/components/common/formik/Input';
import Spinner from 'app/components/customer/Spinner';
import { Dropdown as BlackDropdown } from 'app/components/common/formik/Dropdown';
import * as selectors from 'app/selectors/customer';
import { selectCurrentIntake, selectCurrentIntakeProduct } from 'app/selectors/customer';
import { clearAddressFormErrors } from 'app/actions/customer';
import { PHONE_MASK, phoneDisplayFormatter, ZIP_CODE_MASK, zipCodeDisplayFormatter } from 'app/helpers/formatters';
import { ShippingSchema } from 'app/helpers/validators';
import OutOfServiceAlert from 'app/components/customer/steps/Payment/OutOfServiceAlert';
import { apiRequestPromiseViaDispatch } from 'app/api';
import axios from 'axios';
import ProductNotAvailableAlert from 'app/components/customer/steps/Payment/ProductNotAvailableAlert';
import { BaseIntake, MaximusProduct } from 'app/types/admin/customerUser';
import FormInput from 'app/components/common/formik/FormInput';
import GoogleAutoCompleteWrapper from 'app/components/common/GoogleAutoComplete';
import SmsConsent from 'app/components/customer/Shipping/SmsConsent';

export interface ShippingValues extends CustomerShippingAddress {
  phone_number?: string;
  receive_sms?: boolean;
}

const mapStateToProps = (reduxState: RootState) => {
  const shippingAddress = selectors.selectCustomerShippingAddress(reduxState) || Map();
  const customer = selectors.selectCustomer(reduxState);
  const phone_number = customer.get('phone_number') || '';
  const receive_sms = !!customer.get('receive_sms');
  const { city = '', address_line_1 = '', address_line_2 = '', state = '', postal_code = '' } = shippingAddress.toJS();
  const initialFullName = selectors.selectCustomerFullName(reduxState);
  const initialValues = shippingAddress.merge({
    full_name: initialFullName,
    address_line_1,
    address_line_2,
    city,
    receive_sms,
    state,
    postal_code,
    phone_number: phoneDisplayFormatter(phone_number),
  });
  const initialErrors = reduxState.customer.getIn(['forms', 'update_address_form', 'errors'], {});

  return {
    initialErrors,
    initialValues,
  };
};
export const ShippingFields: FC<{
  disabled?: boolean;
  labels?: boolean;
  className?: string;
  handleStateSelection?: (option: string) => void;
  predictions?: ReactNode;
}> = ({ disabled, className, labels = true, handleStateSelection, predictions }) => (
  <div>
    <div className="mb-4">
      <FormInput
        id="address_line_1"
        label="Street address"
        placeholder="Street 1"
        name="address_line_1"
        data-testid="address_line_1"
      />
      {predictions}
      <FormInput id="address_line_2" placeholder="Street 2" name="address_line_2" data-testid="address_line_2" />
    </div>
    <div className="mb-4">
      <FormInput label="City" placeholder="City" name="city" data-testid="city" />
    </div>
    <div className="flex flex-row align-justify gap-2">
      <div className="flex1">
        <BlackDropdown
          id="state"
          name="state"
          disabled={disabled}
          className={className}
          emptyValue="State"
          showLabel={labels}
          onStateChange={handleStateSelection}
        />
      </div>
      <div className="flex1">
        <InputMask
          id="postal_code"
          name="postal_code"
          label={labels ? 'ZIP Code' : null}
          displayFormatter={zipCodeDisplayFormatter}
          mask={ZIP_CODE_MASK}
          inputMode="numeric"
          placeholder="00000"
          className={className}
          disabled={disabled}
        />
      </div>
    </div>
  </div>
);

export interface IShippingStep {
  initialValues: ImmutableMap<ShippingValues>;
  initialErrors: Record<keyof ShippingValues, string>;
  shippingFormRef: any;
  productPharmacyValid: boolean;
  titleClasses?: string;
  setProductPharmacyValid: (submitted: boolean) => void;
  setShippingFormValid: (submitted: boolean) => void;
  productFromParam?: ImmutableMap<MaximusProduct>;
  intakeFromParam?: ImmutableMap<BaseIntake>;
  currentProductName?: string;
}
const GenericShippingForm = ({
  initialValues,
  initialErrors,
  shippingFormRef,
  productPharmacyValid,
  productFromParam,
  intakeFromParam,
  setProductPharmacyValid,
  setShippingFormValid,
}: IShippingStep) => {
  const product =
    productFromParam ||
    useAppSelector((state) => selectors.selectCustomerProduct(state, selectors.selectCurrentIntakeProduct(state)));
  const currentProductName = useAppSelector(selectCurrentIntakeProduct);
  const pharmacyProductName = product?.get('pharmacy_product_name');
  const validStates = useAppSelector(selectors.selectValidStates);
  const customerId = useAppSelector(selectors.selectCustomerId);
  const intake = intakeFromParam || useAppSelector(selectCurrentIntake);
  const intakeName = intake?.get('name');
  const dispatch = useAppDispatch();

  const onSubmit = async (values: ShippingValues, form) =>
    apiRequestPromiseViaDispatch({
      dispatchFn: dispatch,
      path: '/api/commands',
      body: {
        type: 'update_shipping_address',
        user_id: customerId as string,
        params: {
          ...values,
          phone_number: values.phone_number!.replace(/\D/g, ''),
          intake: intakeName,
        },
      },
      onErrorFn: (errors) => {
        Object.entries(errors?.parsedJson?.errors).forEach((entry) => {
          const [key, value] = entry;
          form.setFieldError(key, value as string);
        });
      },
      form,
    });

  const handleStateSelection = useCallback(
    async (option: string) => {
      if (!option || !pharmacyProductName) return;
      const response = await axios.get('/api/product_pharmacy/product_pharmacy_availability', {
        params: {
          pharmacy_product_name: pharmacyProductName,
          state: option,
        },
      });
      const { product_pharmacy_available } = response.data;
      setProductPharmacyValid(Boolean(product_pharmacy_available));
    },
    [currentProductName, pharmacyProductName, validStates, setProductPharmacyValid],
  );

  return (
    <Formik
      initialValues={initialValues.toJS()}
      validationSchema={ShippingSchema}
      onSubmit={onSubmit}
      innerRef={shippingFormRef}
      validateOnMount={true}
    >
      {({ isSubmitting, values, isValid, setFieldValue, setFieldTouched }: FormikProps<ShippingValues>) => {
        setShippingFormValid(isValid);

        useEffect(() => {
          handleStateSelection(values.state);
        }, [handleStateSelection, values.state]);

        return (
          <Form>
            {values?.state && !validStates.includes(values?.state) && <OutOfServiceAlert validStates={validStates} />}
            {validStates.includes(values?.state) && !productPharmacyValid && <ProductNotAvailableAlert />}

            {isSubmitting ? (
              <Spinner isCenter />
            ) : (
              <>
                <h5 className="mb-6 lg:mb-8">Shipping Address</h5>
                <GoogleAutoCompleteWrapper
                  values={values}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                >
                  <ShippingFields handleStateSelection={handleStateSelection} />
                </GoogleAutoCompleteWrapper>
                <div className="mb-6 mt-2">
                  <InputMask
                    id="phone_number"
                    name="phone_number"
                    onKeyUp={() => dispatch(clearAddressFormErrors())}
                    label="Phone Number"
                    displayFormatter={phoneDisplayFormatter}
                    mask={PHONE_MASK}
                    placeholder="(___) ___-___"
                    inputMode="tel"
                    initialError={initialErrors.phone_number}
                  />
                </div>
                <SmsConsent
                  onChange={(evt) => setFieldValue('receive_sms', evt.target.checked)}
                  checked={values.receive_sms ?? false}
                />
              </>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default connect(mapStateToProps)(GenericShippingForm);
