import React, { FC, useContext, useLayoutEffect } from 'react';
import { chain } from 'lodash';
import IconFailAlert from 'images/Icon-Warning.svg';
import IconMap from 'images/Icon-Map.svg';
import SocialMedia from 'app/components/customer/SocialMedia';
import { EBook } from './EBook';
import { JoinOurDiscord } from './JoinOurDiscord';
import { USMap } from './USMap';
import OPTIONS from '../../../constants/option-groups.json';
import '../css/FailureState.scss';
import { RejectionType } from 'app/constants/enums';
import { SecondaryButton } from 'app/components/common/Button';
import { useAppDispatch, useAppSelector } from 'app/helpers/hooks';
import * as selectors from 'app/selectors/customer';
import * as Routes from 'app/constants/Routes';

import { push } from 'connected-react-router/immutable';
import { ProductContext } from '../Product';
import { trackEvent } from 'app/utils/useSegmentTracking';

const RejectedText: FC = () => (
  <>
    <p className="failure-state__text mv28">
      If you believe you have reached this page in error, please contact our concierge team by emailing&nbsp;
      <a href="mailto:support@maximustribe.com">support@maximustribe.com</a>
      &nbsp;or by using the Live Chat feature on the bottom right corner of your screen.
    </p>
    <p className="failure-state__text mv28">
      In the meantime, you can still be a Maximizer.
      <br />
      Check out the following resources and join the tribe today.
    </p>
  </>
);

const RejectedTextHr: FC = () => (
  <>
    <p className="failure-state__text mv28">
      If, after reviewing your answers, you believe you have reached this page in error, please contact our concierge
      team by emailing&nbsp;
      <a href="mailto:support@maximustribe.com">support@maximustribe.com</a>
      &nbsp;or by using the Live Chat feature on the bottom right corner of your screen.
    </p>
    <p className="failure-state__text mv28">
      In the meantime, you can still be a Maximizer.
      <br />
      Check out the following resources and join the tribe today.
    </p>
  </>
);

const ReviewQuestionnaireButton = () => {
  const dispatch = useAppDispatch();
  const productCtx = useContext(ProductContext);
  const currentIntake = useAppSelector((state) => selectors.selectLastProductIntake(state, productCtx.selectedProduct));
  const intake_name = currentIntake.get('name');
  const currentStrictlyIntakeSteps = useAppSelector((state) =>
    selectors.selectStrictlyProductIntakeStepsByLastIntake(state, productCtx?.selectedProduct),
  );
  const firstStep = currentStrictlyIntakeSteps.get(0);
  const firstStepUrlFriendlyName = selectors.urlFriendlyName(firstStep);

  const onClick = () => {
    dispatch(push(Routes.createIntake(intake_name, firstStepUrlFriendlyName)));
  };

  return (
    <SecondaryButton
      className="failure-state__button"
      data-testid="failure-state-review-questionnaire"
      text="Review Questionnaire"
      onClick={onClick}
    />
  );
};

const HealthRejected: FC = () => {
  return (
    <section className="failure-state__section">
      <img className="failure-state__image__hr" src={IconFailAlert} alt="" />
      <h2 className="failure-state__title__hr center-align">We Apologize</h2>
      <p className="failure-state__text mv24">
        Based on your responses on the Medical Questionnaire, we cannot not currently provide high quality care for you.
      </p>
      <p className="failure-state__text mv24">
        If you think you may have made a mistake, please review your Medical Questionnaire answers. Be sure to answer
        all questions accurately and to the best of your knowledge.
      </p>
      <ReviewQuestionnaireButton />
      <RejectedTextHr />
    </section>
  );
};

const LocationRejected: FC<{ currentProduct?: string }> = ({ currentProduct }) => {
  const states = chain(OPTIONS.states).keyBy('value').mapValues('label').value();
  let validStates = window.signupStates;
  switch (currentProduct) {
    case 'magician':
      validStates = window.magicianStates;
      break;
    case 'lover':
      validStates = window.loverStates;
      break;
    case 'warrior':
      validStates = window.warriorStates;
      break;
    default:
  }

  useLayoutEffect(() => {
    trackEvent('Viewed State Ineligibility Screen', {
      protocol: currentProduct,
    });
  }, [currentProduct]);

  return (
    <section className="failure-state__section center-align">
      <img className="failure-state__image" src={IconMap} alt="" />
      <h2 className="failure-state__title mv28">Coming soon!</h2>
      <p className="failure-state__text mv28">Thank you for your interest in Maximus.</p>
      <p className="failure-state__text mv28">
        Unfortunately, the protocol of interest is not available in your state yet. Good news is we are actively
        expanding and will keep you up to date as we roll out in new states. Note: other protocols may be available in
        your state. (Click the tabs at the top of the page to explore other protocols.)
      </p>

      <p className="failure-state__text mv28">
        Your protocol of interest is currently available in the following states:
      </p>

      {window.signupStates && (
        <>
          <USMap validStates={validStates} />
          <ul className="valid-states flex flex-wrap m28">
            {validStates.map((validState) => (
              <li className="flex items-center" key={validState}>
                <i className="iconfont-check-circle --duotone mr8" aria-hidden="true" />
                {states[validState]}
              </li>
            ))}
          </ul>
        </>
      )}
      <RejectedText />
    </section>
  );
};

const FailureState: FC<{ state: RejectionType; currentProduct?: string; social?: boolean }> = ({
  state,
  currentProduct,
  social = true,
}) => (
  <div className="failure-state centered">
    {
      {
        [RejectionType.HealthRejected]: <HealthRejected />,
        [RejectionType.LocationRejected]: <LocationRejected currentProduct={currentProduct} />,
      }[state]
    }
    <EBook />
    <JoinOurDiscord />
    {social && <SocialMedia className="mb40 section-divider--primary" />}
  </div>
);

export default FailureState;
