import React, { FC } from 'react';

import ShippingCard from './ShippingCard';
import ShippingStep from './Shipping';
import MultimonthPlanSelectionStep from './Payment/Generic/MultimonthPlanSelectionStep';
import LabSelectionStep from './LabSelectionStep';
import CheckpointStep from './Checkpoint';
import IdentityStep from './Identity';
import LocationStep from './Location';
import PharmacyStep from './Pharmacy';
import LabStudyStep from './LabStudy';
import Acknowledgement from './Acknowledgement';
import FollowUpAcknowledgement from './FollowUpAcknowledgement';
import SustainAcknowledgement from './SustainAcknowledgement';
import LoverOnboardingAcknowledgement from './LoverOnboardingAcknowledgement';
import WarriorDummy from './WarriorDummy';
import MagicianOnboardingAcknowledgement from './MagicianOnboardingAcknowledgement';
import ConditionsAndMedications from './ConditionsAndMedications';
import RefillCheckpoint from './RefillCheckpoint';
import RefillConditions from './RefillConditions';
import OnboardingFreeform from './OnboardingFreeform';
import LoverPostScript from './LoverPostScript';
import LoverRefillConditions from './LoverRefillConditions';
import MagicianRefillConditions from './MagicianRefillConditions';
import IdReview from './IdReview';
import UploadPhoto from './UploadPhoto';
import TrackGrowth from './TrackGrowth';
import GenericPaymentStep from 'app/components/customer/steps/Payment/Generic/GenericPaymentStep';
import PreQualified from 'app/components/customer/steps/PreQualified';
import DriverLicenseConfirmation from './DriverLicenseConfirmation';
import ScheduleAppointmentStep from 'app/components/customer/steps/ScheduleAppointmentStep';
import SubProductSelectionStep from 'app/components/customer/steps/Payment/Generic/SubProductSelectionStep';
import UseOwnLabStep from 'app/components/customer/steps/UseOwnLabStep';
import TestosteroneIncreaseGuarantee from 'app/components/customer/steps/TestosteroneIncreaseGuarantee';

const componentMap = {
  location_confirmation: LocationStep,
  identity_verification: IdentityStep,
  shipping_address: ShippingStep,
  multimonth_plan_selection: MultimonthPlanSelectionStep,
  checkpoint: CheckpointStep,
  payment: GenericPaymentStep,
  follow_up_billing: GenericPaymentStep,
  billing: GenericPaymentStep,
  follow_up_shipping_confirmation: ShippingCard,
  shipping_address_confirmation: GenericPaymentStep,
  pharmacy: PharmacyStep,
  schedule_appointment: ScheduleAppointmentStep,
  lover_pharmacy: PharmacyStep,
  intake_intro_acknowledged: Acknowledgement,
  lover_intro: LoverOnboardingAcknowledgement,
  magician_intro: MagicianOnboardingAcknowledgement,
  warrior_intro: WarriorDummy,
  follow_up_intake_intro_acknowledged: FollowUpAcknowledgement,
  sustain_intake_intro_acknowledged: SustainAcknowledgement,
  lab_study_opt_in: LabStudyStep,
  conditions_and_medications: ConditionsAndMedications,
  refill_conditions: RefillConditions,
  refill_checkpoint: RefillCheckpoint,
  freeform: OnboardingFreeform,
  warrior_pharmacy: PharmacyStep,
  magician_pharmacy: PharmacyStep,
  lover_freeform: OnboardingFreeform,
  lover_postscript: LoverPostScript,
  lover_refill_conditions: LoverRefillConditions,
  magician_refill_conditions: MagicianRefillConditions,
  id_review: IdReview,
  track_growth: TrackGrowth,
  front_of_head: UploadPhoto,
  top_of_head: UploadPhoto,
  back_of_head: UploadPhoto,
  warrior_freeform: OnboardingFreeform,
  magician_freeform: OnboardingFreeform,
  king_v2_product_selection: SubProductSelectionStep,
  testosterone_increase_guarantee: TestosteroneIncreaseGuarantee,
  sub_product_selection: SubProductSelectionStep,
  king_prequalified: PreQualified,
  magician_prequalified: PreQualified,
  king_v2_id_confirmation: DriverLicenseConfirmation,
  lab_selection: LabSelectionStep,
  upload_own_lab: UseOwnLabStep,
};

const Steps: FC<{ step: keyof typeof componentMap }> = ({ step }) => {
  const Component = componentMap[step];

  return <Component step={step} />;
};

export default Steps;
