import React from 'react';
import { useField } from 'formik';
import { FormField } from 'mui';

export const NumericField = ({ value, onChange, label, unit }) => {
  const numeric = Number(value);

  return (
    <FormField
      value={numeric === 0 ? '' : String(value)}
      onChange={(e) => onChange(e.target.value)}
      label={label}
      testid="numeric-field"
      type="number"
      placeholder={numeric === 0 ? '0' : 'Enter number'}
      insideElement={unit}
    />
  );
};

const NumericQuestion = ({ question }) => {
  const {
    name,
    data: { min, max, label, unit },
  } = question;
  const [{ value }, , { setValue }] = useField({ id: name, name });

  return (
    <div key={name}>
      <NumericField min={min} max={max} onChange={setValue} value={value} label={label} unit={unit} />
    </div>
  );
};

export default NumericQuestion;
