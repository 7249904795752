import React from 'react';

export const HcgInfo = () => (
  <>
    <h5 className="subtitle">hCG</h5>

    <p className="bold">What is hCG?</p>
    <p className="mb-3">
      hCG (human chorionic gonadotropin) is a prescription medication that mimics luteinizing hormone (LH), helping to
      stimulate your body’s natural testosterone production and support sperm production, especially when used with
      testosterone therapy.
    </p>

    <p className="bold">Who should not take hCG?</p>
    <p className="mb-3">
      Do not take hCG if you:
      <ul className="modal-list">
        <li>Are allergic to hCG or any of its ingredients.</li>
        <li>Have prostate cancer or certain other cancers.</li>
        <li>Are under 18 years of age without medical supervision.</li>
      </ul>
    </p>

    <p className="bold">What should I tell my healthcare provider before taking hCG?</p>
    <p className="mb-3">
      Before you take hCG, tell your healthcare provider if you:
      <ul className="modal-list">
        <li>Have heart, kidney, or liver problems.</li>
        <li>Have a history of prostate or breast cancer.</li>
        <li>Are taking any medications that affect your hormones.</li>
        <li>Have any other medical conditions.</li>
      </ul>
      Tell your healthcare provider about all the medicines you take, including prescription and over-the-counter
      medicines, vitamins, and herbal supplements.
    </p>

    <p className="bold">How should I take hCG?</p>
    <p className="mb-3">
      <ul className="modal-list">
        <li>Take hCG exactly as prescribed by your healthcare provider.</li>
        <li>hCG is usually injected subcutaneously as instructed by your provider.</li>
        <li>Do not change your dose or injection schedule without consulting your healthcare provider.</li>
      </ul>
    </p>
    <p className="bold">What are the possible side effects of hCG?</p>
    <p className="mb-3">
      Common side effects include:
      <ul className="modal-list">
        <li>Headache</li>
        <li>Mood changes, such as irritability or mood swings</li>
        <li>Mild fluid retention.</li>
      </ul>
    </p>

    <p className="bold">Less common side effects include:</p>
    <p className="mb-3">
      <ul className="modal-list">
        <li>Acne.</li>
        <li>Breast tenderness or enlargement.</li>
        <li>Fatigue.</li>
      </ul>
    </p>

    <p className="bold">Serious side effects include:</p>
    <p className="mb-3">
      <ul className="modal-list">
        <li>Severe mood changes.</li>
        <li>Signs of an allergic reaction, such as hives or difficulty breathing.</li>
      </ul>
    </p>

    <p className="bold">Seek emergency medical attention if you experience:</p>
    <p className="mb-3">
      <ul className="modal-list">
        <li>Signs of a blood clot, such as pain or swelling in one leg.</li>
        <li>Severe abdominal pain</li>
      </ul>
    </p>

    <p className="bold">What else should I know about hCG?</p>
    <p className="mb-3">
      <ul className="modal-list">
        <li>hCG helps preserve fertility and testicular function when combined with testosterone therapy.</li>
        <li>Regular blood tests may be needed to monitor hormone levels and adjust treatment.</li>
        <li>Inform your healthcare provider about any new medications or supplements.</li>
      </ul>
    </p>

    <p className="bold">How should I store hCG?</p>
    <p className="mb-3">
      <ul className="modal-list">
        <li>
          Store hCG as directed, typically in the refrigerator or at room temperature away from light and moisture.
        </li>
        <li>Keep out of reach of children.</li>
      </ul>
    </p>

    <p className="mb-3">General information about the safe and effective use of hCG.</p>
    <p className="mb-3">
      Do not use hCG for a condition for which it was not prescribed. Do not share this medication with others.
    </p>
  </>
);
