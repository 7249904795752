/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import CreditCheckIcon from 'images/credit-card-check.svg';
import { useField, useFormikContext } from 'formik';
import Alert from 'app/components/common/Alert';
import CardInput from '../CardInput';
import { PaymentContext } from '../PaymentContext/context';
import { useAppDispatch, useAppSelector } from 'app/helpers/hooks';
import {
  selectCurrentIntake,
  selectCurrentIntakeProduct,
  selectCustomer,
  selectCustomerShippingAddress,
} from 'app/selectors/customer';
import CardsIcons from 'images/payment/cards-icons.svg';
import { ShippingFields } from 'app/components/customer/steps/Shipping';
import SetProductDialog from '@setproduct-ui/core/Dialog';
import { fromJS } from 'immutable';
import * as actions from 'app/actions/customer';
import ApplePayButton from 'app/components/customer/steps/Payment/ApplePay/ApplePayButton';
import { isProduction } from 'app/helpers/env';
import createCheckoutHelper from 'app/helpers/createCheckoutHelper';
import * as selectors from 'app/selectors/customer';
import { NOOP } from 'app/actions/customer';
import KlarnaButton from 'app/components/customer/steps/Payment/Klarna/KlarnaButton';
import { isFunction } from 'app/utils';
import GenericSelfPay from 'app/components/customer/steps/Payment/Generic/GenericSelfPay';
import FormCheckbox from 'app/components/common/formik/FormCheckbox';
import { Button } from 'mui';

const cmdType = 'add_stripe_payment_method';

const GenericPaymentMethod = ({
  currentPaymentMethod,
  paymentErrorMessage,
  setOptInParams,
  onCheckout,
  disabled,
  priceCalculator,
  bodyClassName = 'payment_card',
  shippingFormRef,
  setUnavailableProductDialog,
  laasFlow = false,
}) => {
  const [paymentMethod, setPaymentMethod] = useState(currentPaymentMethod);
  const dispatch = useAppDispatch();
  useEffect(() => {
    setPaymentMethod(currentPaymentMethod);
  }, [currentPaymentMethod]);
  const currentIntake = useAppSelector(selectCurrentIntake);

  const customer = useAppSelector(selectCustomer);
  const shippingAddress = useAppSelector(selectCustomerShippingAddress);
  const applePayEnabled = customer.get('apple_pay_enabled');
  const [showApplePay, setShowApplePay] = useState(false);
  const klarnaEnabled = customer.get('klarna_enabled');
  const multiplePaymentMethods = showApplePay || klarnaEnabled;
  const paymentContext = useContext(PaymentContext);
  const expanded = !currentPaymentMethod?.get('card_last4');
  const [showAlert, setShowAlert] = useState(false);
  const [showCheckoutForm, setShowCheckoutForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState(paymentErrorMessage);
  const [openModalPaymentMethod, setOpenModalPaymentMethod] = useState(false);
  const { handleSubmit, isSubmitting, values } = useFormikContext<{
    full_name: string;
    city: string;
    state: string;
    address_line_1: string;
    address_line_2: string;
    postal_code: string;
  }>();

  const [field, , { setValue }] = useField('updateAddress');
  const billingDifferent = !!field.value;
  const errorRef = useRef<HTMLDivElement>(null);
  const intake = useAppSelector(selectCurrentIntake);
  const currentProductName = useAppSelector(selectCurrentIntakeProduct);
  const product = useAppSelector((state) => selectors.selectCustomerProduct(state, currentProductName));
  const checkoutHelper = !laasFlow ? createCheckoutHelper(intake, customer, product, true) : undefined;
  const subProductName = checkoutHelper?.subProductNameForIntakeCheckout();
  const isKingV2OralTrt = checkoutHelper?.isKingV2OralTrt(subProductName);

  useEffect(() => {
    if (paymentErrorMessage) {
      setErrorMessage(paymentErrorMessage);
      errorRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [paymentErrorMessage]);

  const updatePaymentMethod = useCallback(async (result: any, payment_method_type: string) => {
    if (shippingFormRef.current?.dirty) {
      const response = (await shippingFormRef.current.submitForm()) as { type: string } | undefined;
      if (response?.type === NOOP) return;
    }

    isFunction(setOptInParams) &&
      setOptInParams({
        intake_name: currentIntake?.get('name'),
        card_brand: result.cardBrand,
        card_last4: result.cardLastFour,
        payment_method_id: result.paymentMethodId,
      });
    if (currentPaymentMethod?.get('card_last4')) {
      const action = actions.apiRequestUserCommand({
        cmdType,
        params: {
          card_brand: result?.cardBrand,
          card_last4: result?.cardLastFour,
          payment_method_id: result?.paymentMethodId,
          payment_method_source: paymentContext?.paymentProvider,
          payment_method_type: payment_method_type,
        },
        context: {},
      });
      dispatch(action);
    }
    setPaymentMethod(() => fromJS({ card_brand: result.cardBrand, card_last4: result.cardLastFour }));
  }, []);

  const processApplePay = (result) => {
    if (result) {
      updatePaymentMethod(result, 'apple_pay').then(() => {
        onCheckout(result);
      });
    }
  };

  const processKlarnaPayment = (result) => {
    if (result) {
      updatePaymentMethod(result, 'klarna').then(() => {
        onCheckout(result);
      });
    }
  };

  const onSubmit = useCallback(async () => {
    if (isKingV2OralTrt) {
      const customerState = shippingFormRef.current?.values?.state || shippingAddress.get('state');
      const kingV2AvailableStates = intake.get('king_v2_available_states');
      const validOralTrtState = kingV2AvailableStates.includes(customerState);
      if (!validOralTrtState) {
        setOpenModalPaymentMethod(false);
        setUnavailableProductDialog(!validOralTrtState);
        return;
      }
    }
    try {
      const full_name = `${customer.get('first_name')} ${customer.get('last_name')}`;
      const result = await paymentContext?.createPaymentMethod({ ...values, full_name });
      if (result) {
        await updatePaymentMethod(result, 'credit_card');
      }

      setShowAlert(true);
    } finally {
      setOpenModalPaymentMethod(false);
    }
  }, [expanded, currentIntake?.get('name'), handleSubmit, paymentContext, setOptInParams, values]);

  const applePayButton = () => {
    if (window.ApplePaySession) {
      const merchantIdentifier = isProduction()
        ? 'merchant.com.maximustribe.app'
        : 'merchant.com.maximustribe.app.test';
      const promise = ApplePaySession.canMakePaymentsWithActiveCard(merchantIdentifier);

      promise
        .then((canMakePayments) => {
          setShowApplePay(canMakePayments);
        })
        .catch((error) => {
          console.error(error);
        });

      if (showApplePay) {
        return (
          <ApplePayButton
            aria-label="Pay with Apple Pay"
            onSuccessfulPayment={processApplePay}
            amount={priceCalculator.totalDueToday()}
            disabled={disabled}
          />
        );
      }
    }
  };

  const payWithCardButton = () => (
    <Button onClick={() => setShowCheckoutForm(true)} data-testid="next-step">
      Pay with Credit Card
    </Button>
  );

  return (
    <>
      {showAlert && (
        <Alert onClose={() => setShowAlert(false)} className="mb24">
          Payment method updated successfully
        </Alert>
      )}
      {errorMessage && (
        <div ref={errorRef}>
          <Alert noClose type="error" className="mb24">
            {errorMessage}
          </Alert>
        </div>
      )}
      <div className={`${bodyClassName}`}>
        {applePayEnabled && applePayButton()}
        {klarnaEnabled && (
          <KlarnaButton
            aria-label="Pay with Klarna"
            disabled={disabled}
            onSuccessfulPayment={processKlarnaPayment}
            priceCalculator={priceCalculator}
          />
        )}
        {multiplePaymentMethods && !showCheckoutForm && payWithCardButton()}
        <div
          style={{ display: !multiplePaymentMethods || showCheckoutForm ? 'flex' : 'none' }}
          className="flex flex-col gap-y-6"
        >
          {multiplePaymentMethods && <hr className="mt-6" />}
          <div className="flex flex-col gap-y-4 lg:gap-y-6">
            {!!paymentMethod?.get('card_last4') && (
              <div className="flex">
                <div className="flex flex1 flex-row gap-x-2 items-start">
                  <p>{paymentMethod?.get('card_brand')}:</p>
                  <div className="flex flex-row gap-x-2 items-center">
                    <div>**** **** **** {paymentMethod?.get('card_last4')}</div>
                    <div>
                      <img src={CreditCheckIcon} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {expanded && <CardInput />}
            {!expanded && (
              <Button onClick={() => setOpenModalPaymentMethod(true)} data-testid="update-payment-method">
                Update payment info
              </Button>
            )}

            {expanded && (
              <div className="payment_method__accept">
                <div>
                  <img src={CardsIcons} />
                </div>
                <div className="mt8">Including HSA/FSA cards</div>
              </div>
            )}
            <div className="flex1 mt-4">
              <FormCheckbox
                name="updateAddress"
                className="items-center"
                label={<p className="pt-[2px]">Billing address different from shipping</p>}
                checked={billingDifferent}
                onChange={(e) => {
                  if (billingDifferent && !e.target.checked) {
                    setValue('updateAddress', false);
                  }
                  setValue(e.target.checked);
                }}
              />
            </div>
            {billingDifferent && (
              <>
                <hr />
                <ShippingFields />
              </>
            )}
          </div>
          <hr />
          <GenericSelfPay />
          <Button type="button" data-testid="next-step" onClick={onCheckout} disabled={disabled}>
            Checkout
          </Button>
        </div>
      </div>

      <SetProductDialog
        isOpen={openModalPaymentMethod}
        text={
          <div className="flex flex-col gap-y-4">
            <CardInput />
            <div className="payment_method__accept">
              <div>
                <img src={CardsIcons} />
              </div>
            </div>
            <p>including HSA/FSA cards</p>

            <hr />
            <div className="flex flex-col gap-y-2">
              <Button
                data-testid="save-payment-method"
                onClick={onSubmit}
                disabled={isSubmitting || paymentContext?.invalid}
              >
                Save
              </Button>
              <Button
                variant="text"
                onClick={() => setOpenModalPaymentMethod(false)}
                data-testid="close-update-payment-method"
              >
                Cancel
              </Button>
            </div>
          </div>
        }
        title="Payment Details"
        onClose={() => setOpenModalPaymentMethod(false)}
        className="manage_subscription__cancel_modal generic_payment_page__modal align-left maximized"
      />
    </>
  );
};

export default GenericPaymentMethod;
