import React, { useMemo, useState } from 'react';
import { FormSelect } from 'mui';
import Question from 'app/constants/Question';
import './css/Dropdown.scss';

const Dropdown = ({
  name,
  value,
  onClick,
  className,
  emptyValue,
  showLabel = true,
  isSearchable = true,
  disabled,
  error,
  errorHelperText,
  ...restProps
}) => {
  const { options, label: defaultLabel } = useMemo(() => new Question(name), [name]);
  const opt = options?.find((opt) => opt.value === value);

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleMenuOpen = () => {
    setScrollPosition(window.scrollY);
  };

  const handleMenuClose = () => {
    setTimeout(() => {
      window.scrollTo(0, scrollPosition);
    }, 0);
  };

  return (
    <FormSelect
      {...restProps}
      name={name}
      options={options}
      value={opt}
      onChange={onClick}
      className={className}
      placeholder={emptyValue}
      labelHidden={!showLabel}
      isSearchable={isSearchable}
      disabled={disabled}
      error={errorHelperText}
      valid={!error}
      label={restProps?.label || defaultLabel}
      testid={restProps.testId}
      onMenuOpen={handleMenuOpen}
      onMenuClose={handleMenuClose}
    />
  );
};

export default Dropdown;
