import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as selectors from 'app/selectors/customer';
import * as actions from 'app/actions/customer';
import { push } from 'connected-react-router/immutable';
import '../css/UploadPhoto.scss';
import HeadingWithIcon from 'app/components/common/HeadingWithIcon';
import StockListIcon from 'images/stocks-list-icon.svg';
import ScalpGrowth from 'images/i-scalp-growth.svg';
import UploadPhoto from 'app/components/customer/steps/UploadPhoto';
import protocolNames from 'app/utils/protocolNames';
import { AvailableProducts } from 'app/constants/Products';
import { Button } from 'mui';

const mapStateToProps = (state) => ({
  onSuccessAction: push(selectors.selectNextStepPath(state)),
  step: selectors.selectCurrentIntakeStep(state),
  intake: selectors.selectCurrentIntake(state),
});

const pictures = ['Front of Head (Hairline)', 'Top of Head (Scalp)', 'Back of Head'];

const TrackGrowth = ({ dispatch, step, onSuccessAction, intake }) => {
  // Uses for ability to upload photos outside of intake
  const [switcher, setSwitcher] = useState(false);
  if (intake && step.get('completed')) {
    setTimeout(() => dispatch(onSuccessAction), 0);
  }
  const onSubmit = () => {
    const action = actions.apiRequestUserCommand({
      cmdType: 'answer_questions',
      params: { [step.get('name')]: true, intake: intake.get('name') },
      context: { onSuccessAction },
    });
    dispatch(action);
  };

  return (
    <div className="centered flex-column">
      <div className="main-area">
        {!switcher && (
          <>
            <p className="title-display">{protocolNames[AvailableProducts.Warrior]} Protocol</p>
            <div className="text-container">
              <h3 className="center-text-container upload-text">Track Your Growth</h3>
            </div>
            <div className="track-growth-text mt20 mb40">
              <p className="mb20">
                Taking pictures of your hair periodically will allow you and your doctor to track your progress on the
                {' ' + protocolNames[AvailableProducts.Warrior]} Protocol.
              </p>
              <p>In order to get a complete view of your progress you will be asked to take three pictures:</p>
            </div>
            <ul>
              {pictures.map((benefit) => (
                <li key={benefit}>
                  <HeadingWithIcon icon={StockListIcon} text={benefit} tag="p" className="my-2" />
                </li>
              ))}
            </ul>
            <div className="picture-instruction">
              <img src={ScalpGrowth} alt="Instruction" />
            </div>
            <div className="tip-text mt40">
              <p>
                <span className="tip">Tip:</span> It is best to take these pictures monthly with your hair completely
                dry and at the same length. Taking pictures shortly after a fresh haircut can help with consistency.
              </p>
            </div>
            <div className="button-container">
              {!!intake && <Button onClick={onSubmit}>Track my growth</Button>}
              {!intake && <Button onClick={() => setSwitcher(true)}>Track my growth</Button>}
            </div>
          </>
        )}
        {switcher && <UploadPhoto />}
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(TrackGrowth);
